import React from 'react'
import { useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { TenantForm } from '../../../components/Forms/TenantForm'
import { Notifications } from '../../../components/Notification/Notification'
import { Spinner } from '../../../components/Loader/Spinner'
import { useCreateTenant } from '../../../hooks/useTenants'

export default function TenantsCreate() {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { mutate, isLoading, isError, error } = useCreateTenant()

  function saveTenant(tenantData) {
    mutate(tenantData, {
      onSuccess: () => history.push('/admin/tenants'),
      onSettled: () => {
        queryClient.invalidateQueries('tenants')
      }
    })
  }

  return (
    <>
      {isLoading ? <Spinner /> : null}

      <Notifications
        isOpenProp={isError}
        message={isError ? error.response?.data?.message || error.message : ''}
        color="danger"
        timeToClose={6000}
        position="tr"
      />
      <TenantForm
        onSubmit={saveTenant}
        submitText={
          isLoading ? 'Saving...' : isError ? 'Error!' : 'Save Tenant'
        }
      />
    </>
  )
}
