import React, { useEffect, useRef, useState } from 'react'
// Style
// import { makeStyles } from '@material-ui/core/styles'
// import orbisStyle from '../../assets/jss/views/Orbis'
// import { getOrbisContext } from '../../services/apiApps'

import loadingGif from '../../assets/img/ajax-loader.gif'
import { env } from '../../utils/Constants'

// Api Calls
import apiApps from '../../services/apiApps'
import apiAppsProd from '../../services/apiAppsProd'
const getOrbisContext = (env === 'local') ? apiApps.getOrbisContext : apiAppsProd.getOrbisContext

// const useStyles = makeStyles(orbisStyle)

export default function Orbis (props) {
  const [context, setContext] = useState('')
  const [bvdId, setBvdId] = React.useState(props.bvdId ? props.bvdId : '')

  const isMounted = useRef(false)
  useEffect(() => {
    if (!isMounted.current) {
      if (!props.bvdId) {
        var url_string = window.location.href
        var url = new URL(url_string)
        var bvdIdParameter = url.searchParams.get('bvdId')
        setBvdId(bvdIdParameter)
      }
      isMounted.current = true
      getOrbisContext(setContext)
    } else {
    }
    return () => {}
  }, [props.bvdId])
  return (
    <div>
      {context ? (
        <iframe
          id="iframeOrbis"
          title="Orbis"
          width="100%"
          height="800px"
          src={`https://orbis4.bvdinfo.com/orbis/Companies/report?BvDID=${bvdId}&context=${context}`}
          frameBorder="0"
          style={{
            backgroundImage: 'url(' + loadingGif + ')',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}
          allowFullScreen={true}
        ></iframe>
      ) : (
        <iframe
          id="iframeOrbis"
          title="Orbis"
          width="100%"
          height="800px"
          frameBorder="0"
          style={{
            backgroundImage: 'url(' + loadingGif + ')',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}
          allowFullScreen={true}
        ></iframe>
      )}
      {/* )} */}
    </div>
  )
}
