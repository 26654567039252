import defaultStyle from '../Default'

const suppliersCriteriaStyle = {
  checkBox: {
    margin: '20px 10px 20px 10px'
  },
  ...defaultStyle,
  card: {
    ...defaultStyle.card,
    width: '100%',
    height: '100%'
  },
  defaultButton: {
    ...defaultStyle.defaultButton,
    float: 'right',
    marginTop: '10px'
  },
  textField: {
    ...defaultStyle.textField,
    width: '90%',
    margin: '20px 10px 20px 10px'
  },
  selectField: {
    ...defaultStyle.selectField,
    width: '90%',
    margin: '20px 10px 20px 10px'
  }
}

export default suppliersCriteriaStyle
