import { primaryColor } from '../Colors'
import defaultStyle from '../Default'

const configurationsStyle = theme => ({
  padding10: { padding: '10px !important' },
  appBar: {
    overflow: 'auto',
    '@media (min-width: 1300px)': {
      height: '100%'
    }
  },
  listItem: {
    color: primaryColor
  },
  circularProgress: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  clearAvatar: {
    backgroundColor: 'transparent'
  },
  ...defaultStyle,
  defaultButton: {
    ...defaultStyle.defaultButton,
    marginBottom: '10%'
  }
})

export default configurationsStyle
