import React from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getIamService } from '../../apis/iam'

// Core
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

// Style
import { makeStyles } from '@material-ui/core/styles'
import rolesStyle from '../../assets/jss/views/Roles'

// Icons
import Edit from '@material-ui/icons/Edit'
//Component
import { Spinner } from '../../components/Loader/Spinner'
import { Notifications } from '../../components/Notification/Notification'
import CustomTreeTable from '../../components/CustomTreeTable/CustomTreeTable'

const useStyles = makeStyles(rolesStyle)

export default function Roles () {
  const classes = useStyles()
  const history = useHistory()
  const columns = [
    { title: 'Id', field: 'roleId' },
    { title: 'Name', field: 'name' },
    { title: 'Description', field: 'description' }
  ]
  const { data, isLoading, error, isError, isFetching } = useQuery(
    'roles',
    () =>
      getIamService()
        .get('/roles')
        .then(result => {
          return result.data
        })
  )

  function newRole () {
    history.push('/admin/roles/create')
  }

  return (
    <>
      {isLoading ? <Spinner /> : null}
      <Notifications
        isOpenProp={isError}
        message={isError ? error.response?.data?.message || error.message : ''}
        color="danger"
        timeToClose={6000}
        position="tr"
      />
      <h3 className={classes.h3}>Roles{isFetching ? '...' : null}</h3>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <CustomTreeTable
            rightButton={
              <Button
                style={{ width: '100px', float: 'right' }}
                className={classes.defaultPrimaryButton}
                onClick={() => newRole()}
              >
                + Add Role
              </Button>
            }
            columns={columns}
            data={data ? data : []}
            options={{
              actionsColumnIndex: -1,
              sorting: true,
              showTitle: false
            }}
            actions={[
              {
                icon: () => <Edit />,
                tooltip: 'Edit Role',
                onClick: (event, rowData) => {
                  history.push(`/admin/roles/edit/${rowData.roleId}`)
                }
              }
            ]}
          />
        </Grid>
      </Grid>
    </>
  )
}
