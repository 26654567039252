import React, { useEffect } from 'react'
import { Spinner } from '../../components/Loader/Spinner'
import { signOut } from '../../layout/Admin/Admin'

async function logout () {
  signOut()
}
export default function LogOut () {
  useEffect(() => {
    logout()

    // window.addEventListener('resize', listenerWidth)
  })
  return (
    <div>
      <Spinner /> Signing out
    </div>
  )
}
