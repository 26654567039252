import * as React from 'react'
import Amplify, { Auth } from 'aws-amplify'
import { useQuery } from 'react-query'

// Service
import { getIamService } from '../apis/iam'

// Components
import { Spinner } from '../components/Loader/Spinner'

// Views
import ErrorTenant from '../views/ErrorTenant/ErrorTenant'
import { useGetTenantConfigure } from '../hooks/useTenants'
//redux
import { useDispatch } from 'react-redux'
import { setSelectedApp, setAppList } from '../store/MainState'
import { env } from '../utils/Constants'

import apiApps from '../services/apiApps'
import apiAppsProd from '../services/apiAppsProd'

const getApplicationList =
  env === 'local' ? apiApps.getApplicationList : apiAppsProd.getApplicationList

const AuthContext = React.createContext()
AuthContext.displayName = 'AuthContext'
export function getTenant () {
  if (window && window.location && window.location.hostname) {
    const splittedDomain = window.location.hostname.split('.')
    return splittedDomain[0] === 'localhost' ? 'test07' : splittedDomain[0]
  }
}
const AuthProvider = props => {
  const [authState, setAuthState] = React.useState({
    user: null,
    statusRequest: 'idle'
  })
  const [hasTenant, setHasTenant] = React.useState(false)
  const [hasAwsConfigure, setHasAwsConfigure] = React.useState(true)
  const tenantId = getTenant()
  const { data, isLoading, isError, isIdle } = useGetTenantConfigure({
    tenantId,
    hasAwsConfigure
  })

  const { data: permissions, isLoading: isLoadingPerm } = useQuery(
    ['infoRole', authState.roles],
    () =>
      getIamService()
        .get(`roles/${authState.roles}`)
        .then(result => {
          let formatedData = {}
          let data = {}
          for (let permissions of result.data.permissions) {
            const route = permissions.split('.')
            if (route.length > 2) {
              data[route[1]] = [route[2]]
              formatedData[route] = data
            } else {
              data[route[1]] = ['*']
              formatedData[route[0]] = data
            }
          }
          // return formatedData
          return result.data.permissions
        }),
    {
      enabled: authState.roles ? true : false
    }
  )
  const dispatch = useDispatch()
  React.useEffect(() => {
    const configure = window.localStorage.getItem(
      `CognitoIdentity.Configure.${getTenant()}`
    )

    if (configure) {
      Amplify.configure(JSON.parse(configure))

      getApplicationList(applist => {
        if (applist && applist.length > 0) {
          dispatch(setSelectedApp(applist[0]))
          dispatch(setAppList(applist))
        }
        setHasTenant(true)
      })

      return
    }

    setHasAwsConfigure(false)
  }, [dispatch])

  React.useEffect(() => {
    if (isLoading || isError || isIdle) {
      return
    }

    window.localStorage.removeItem('externalLogin')
    window.localStorage.removeItem(`BvD.SelectedApp.${getTenant()}`)
    window.localStorage.removeItem(`BvD.AppList.${getTenant()}`)
    window.sessionStorage.removeItem(`BvD.SelectedApp.${getTenant()}`)

    if (data.status !== 401) {
      const awsconfig = {
        Auth: {
          region: data.region,
          userPoolId: data.userPoolId,
          userPoolWebClientId: data.appClientId,
          mandatorySignIn: false,
          oauth: {
            domain: data.authDomain,
            scope: [
              'phone',
              'email',
              'profile',
              'openid',
              'aws.cognito.signin.user.admin'
            ],
            redirectSignIn: `${window.location.origin}/admin`,
            redirectSignOut: `${window.location.origin}/auth/login-page`,
            responseType: 'token'
          }
        }
      }
      getApplicationList(applist => {
        if (applist && applist.length > 0) {
          dispatch(setSelectedApp(applist[0]))
          dispatch(setAppList(applist))
        }
        setHasTenant(true)
      })

      window.localStorage.setItem(
        `CognitoIdentity.Configure.${getTenant()}`,
        JSON.stringify(awsconfig)
      )
    }

    setHasTenant(true)
  }, [
    isLoading,
    isError,
    isIdle,
    data?.status,
    data?.region,
    data?.userPoolId,
    data?.appClientId,
    data?.authDomain,
    dispatch
  ])

  React.useEffect(() => {
    if (!hasTenant) {
      return
    }
    getUser()
  }, [hasTenant])

  function getUser () {
    setAuthState({ user: null, roles: null, statusRequest: 'Loading' })
    return Auth.currentAuthenticatedUser()
      .then(userData => {
        localStorage.setItem('externalLogin', 'true')
        setAuthState({
          user: userData,
          roles: userData.attributes['custom:roles'],
          tenantId: userData.attributes['custom:tenant_id'],
          statusRequest: 'Completed'
        })
      })
      .catch(() => {
        function inIframe () {
          try {
            return window.self !== window.top
          } catch (e) {
            return true
          }
        }

        window.localStorage.removeItem('externalLogin')
        window.localStorage.removeItem(`BvD.SelectedApp.${getTenant()}`)
        window.localStorage.removeItem(`BvD.AppList.${getTenant()}`)
        window.sessionStorage.removeItem(`BvD.SelectedApp.${getTenant()}`)
        window.sessionStorage.removeItem(`BvD.AppList.${getTenant()}`)
        if (inIframe()) {
          window.location.href = window.location.origin + '/e/login'
        } else {
          debugger
          if (localStorage.getItem('logginOut')) {
            localStorage.removeItem('logginOut')
            window.location.reload()
          } else Auth.federatedSignIn()
        }
        setAuthState({ user: null, roles: null, statusRequest: 'Fail' })
      })
  }

  if (isError) {
    return <ErrorTenant />
  }

  if (
    authState.statusRequest === 'Loading' ||
    authState.statusRequest === 'idle' ||
    isLoading ||
    isLoadingPerm
  ) {
    return <Spinner />
  }

  return (
    <AuthContext.Provider
      value={{
        user: authState.user,
        roles: authState.roles,
        tenant: authState.tenantId,
        permissions: permissions
      }}
      {...props}
    />
  )
}

function useAuth () {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export { AuthProvider, useAuth }
