import React, { useEffect, useRef } from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import styles from '../../assets/jss/layout/Admin'
import Select from 'react-select'
//Icons
import AribaIcon from '../../assets/img/icons/aribaTransparent.png'
import { Grid, Typography, Button } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { Spinner } from '../../components/Loader/Spinner'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import OrbisIcon from '../../assets/img/icons/orbisTransparent.png'
import Catalyst from './Catalyst'
import Close from '@material-ui/icons/Close'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { env } from '../../utils/Constants'

// Api Calls
import apiApps from '../../services/apiApps'
import apiAppsProd from '../../services/apiAppsProd'
const getCatalystAssesmentsWithCallback =
  env === 'local'
    ? apiApps.getCatalystAssesmentsWithCallback
    : apiAppsProd.getCatalystAssesmentsWithCallback
const getCatalystAssesmentsBySupplierIdWithCallback =
  env === 'local'
    ? apiApps.getCatalystAssesmentsBySupplierIdWithCallback
    : apiAppsProd.getCatalystAssesmentsBySupplierIdWithCallback
const startCatalystAssesment =
  env === 'local'
    ? apiApps.startCatalystAssesment
    : apiAppsProd.startCatalystAssesment

const useStyles = makeStyles(styles)

export default function AssignBvDIdTable (props) {
  const [mainAssesmentLabel, setMainAssesmentLabel] = React.useState(
    'Selected Assesment'
  )
  const [openCatalyst, setOpenCatalyst] = React.useState(false)
  const [selectedCatalyst, setSelectedCatalyst] = React.useState(undefined)
  const [catalystList, setCatalystList] = React.useState(undefined)
  const [isLoading, setIsLoading] = React.useState(true)
  const [showDialog, setShowDialog] = React.useState(false)
  const [dialogId, setDialogId] = React.useState(null)

  const [models, setModels] = React.useState([])
  const [selectedModel, setSelectedModel] = React.useState(undefined)

  const isMounted = useRef(false)
  useEffect(() => {
    if (!isMounted.current) {
      var url_string = window.location.href
      var url = new URL(url_string)
      var guid = url.searchParams.get('id')
      var supplierId = url.searchParams.get('supplierId')
      var newId = url.searchParams.get('newId')
      if (newId && supplierId) {
        getCatalystAssesmentsBySupplierIdWithCallback(
          supplierId,
          (list, modelList) => {
            getAssesmentCallback(list, modelList)
            setMainAssesmentLabel('New Assesment')
          },
          newId
        )
      } else if (supplierId) {
        getCatalystAssesmentsBySupplierIdWithCallback(
          supplierId,
          getAssesmentCallback
        )
      } else {
        if (guid) {
          getCatalystAssesmentsWithCallback(guid, (list, modelList) => {
            getAssesmentCallback(list, modelList)
            setMainAssesmentLabel('Selected Assesment')
          })
        }
      }

      isMounted.current = true
    } else {
    }
    // this function will be ran when the component is re-renderd or unmounted
    return () => {}
    //you need to add in this array the properties that need to be checked for changes and the ones you will be using in this function
    //, if one property changes, the useEffect function will be ran again (once per DOM change)
    // you can send all the props and if something updated this function will be ran
  }, [])
  function getAssesmentCallback (list, modelList) {
    setCatalystList(list)
    if (modelList && modelList.length > 0) {
      let modelListOptions = modelList.map(model => {
        return { label: model.Name, value: model.Id }
      })

      setModels(modelListOptions)
    }
    setIsLoading(false)
  }
  const classes = useStyles()
  function CatalystCard (catalyst) {
    if (catalyst) {
      return (
        <Card>
          <Grid container>
            <Grid item xs={6}>
              <CardHeader subheader="Compliance Catalyst Assesment"></CardHeader>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12}>
                  <IconButton
                    style={{ float: 'right' }}
                    onClick={() => {
                      setOpenCatalyst(true)
                      setSelectedCatalyst(catalyst)
                    }}
                  >
                    <img
                      alt="OrbisIcon"
                      style={{ width: '25px' }}
                      src={OrbisIcon}
                    />
                    <span style={{ fontSize: '15px', paddingTop: '2.5px' }}>
                      OPEN IN CATALYST
                    </span>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <div style={{ paddingBottom: '5px' }}>
                  <span style={{ fontWeight: 'bold' }}>Score: </span>
                  <span
                    style={{
                      borderRadius: '5px',
                      padding: '5px',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      color: 'white',
                      background:
                        catalyst.scoreLabel === 'Low'
                          ? '#a1c83b'
                          : catalyst.scoreLabel === 'Medium'
                          ? '#f88802'
                          : catalyst.scoreLabel === 'High'
                          ? '#da0018'
                          : '#001489'
                    }}
                  >
                    {(catalyst.scoreLabel ? catalyst.scoreLabel + ' | ' : '') +
                      (catalyst.score !== undefined ? catalyst.score : '...')}
                  </span>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{ paddingBottom: '5px' }}>
                  <span style={{ fontWeight: 'bold' }}>Status: </span>
                  {catalyst.status}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{ paddingBottom: '5px' }}>
                  <span style={{ fontWeight: 'bold' }}>Model: </span>
                  {catalyst.model}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{ paddingBottom: '5px' }}>
                  <span style={{ fontWeight: 'bold' }}>Started By: </span>
                  {catalyst.startedUser}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{ paddingBottom: '5px' }}>
                  <span style={{ fontWeight: 'bold' }}>Started Date: </span>
                  {new Date(
                    catalyst.startedDate.toString()
                  ).toLocaleDateString()}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{ paddingBottom: '5px' }}>
                  <span style={{ fontWeight: 'bold' }}>Modified By: </span>
                  {catalyst.modificationUser}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{ paddingBottom: '5px' }}>
                  <span style={{ fontWeight: 'bold' }}>Modified Date: </span>
                  {new Date(
                    catalyst.modifiedDate.toString()
                  ).toLocaleDateString()}
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )
    }
    return
  }
  function getCatalystSize (length) {
    switch (length) {
      case 1:
        return 12
      case 2:
        return 6
      case 3:
        return 6

      default:
        return 6
    }
  }
  return (
    <div>
      {isLoading ? <Spinner /> : ''}
      {catalystList ? (
        <div>
          {props.backFunction ? (
            <IconButton
              onClick={() => {
                props.backFunction()
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          ) : (
            ''
          )}
          <Grid container>
            <Dialog
              id={'dialogId'}
              open={showDialog}
              fullWidth
              maxWidth={'md'}
              style={{ overflow: 'hidden', maxWidth: '' }}
            >
              <div>
                <IconButton style={{ float: 'right' }}>
                  <Close
                    onClick={() => {
                      setShowDialog(false)
                    }}
                  />
                </IconButton>
              </div>

              <div style={{ height: '100px', marginBottom: '30px' }}>
                <Grid container>
                  <Grid xs={12}>
                    <span
                      className={classes.titleBar}
                      style={{ fontSize: '18px' }}
                    >
                      Select Model
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ width: '50%', margin: 'auto' }}>
                      <Select
                        value={selectedModel}
                        onChange={e => {
                          setSelectedModel(e)
                        }}
                        styles={base => ({ ...base, zIndex: 9999 })}
                        options={models}
                        menuPortalTarget={dialogId}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        width: '90%',
                        margin: 'auto',
                        paddingTop: '10px'
                      }}
                    >
                      <Button
                        className={classes.defaultButton}
                        style={{ float: 'right', width: '250px' }}
                        onClick={async () => {
                          setShowDialog(false)
                          setIsLoading(true)
                          if (
                            selectedModel &&
                            selectedModel.value &&
                            catalystList.aribaSupplierId
                          ) {
                            let catalystAssesmentResult = await startCatalystAssesment(
                              catalystList.bvdId,
                              selectedModel.value
                            )

                            if (
                              catalystAssesmentResult &&
                              catalystAssesmentResult.success &&
                              catalystAssesmentResult.data &&
                              catalystAssesmentResult.data.data &&
                              catalystAssesmentResult.data.data.SelectionId
                            )
                              setIsLoading(false)
                            window.location.href = `catalystAssesments?supplierId=${catalystList.aribaSupplierId}&newId=${catalystAssesmentResult.data.data.SelectionId.CF_RA_Id}`
                          }
                        }}
                      >
                        Start Assesment
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Dialog>
            <Grid item xs={8}>
              <Typography>
                <span
                  style={{ fontSize: '23px' }}
                >{`Supplier: ${catalystList.name} BvD ID ${catalystList.bvdId}`}</span>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {catalystList.realm && catalystList.aribaSupplierId ? (
                <IconButton
                  style={{ float: 'right' }}
                  onClick={() => {
                    setShowDialog(true)
                    setTimeout(() => {
                      setDialogId(document.getElementById('dialogId'))
                    }, 1000)
                  }}
                >
                  <img style={{ width: '25px' }} src={OrbisIcon} alt="" />
                  <span style={{ fontSize: '15px', paddingTop: '2.5px' }}>
                    New Assesment
                  </span>
                </IconButton>
              ) : (
                ''
              )}
            </Grid>
            <Grid item xs={2}>
              {catalystList.realm && catalystList.aribaSupplierId ? (
                <IconButton
                  style={{ float: 'right' }}
                  onClick={() => {
                    window.open(
                      `https://s1.au.cloud.ariba.com/Sourcing/Main/ad/viewSupplier360Profile/ariba.sourcing.dashboard.SMMainActionsController?realm=${catalystList.realm}&smVendorId=${catalystList.aribaSupplierId}`
                    )
                  }}
                >
                  <img style={{ width: '25px' }} src={AribaIcon} alt="" />
                  <span style={{ fontSize: '15px', paddingTop: '2.5px' }}>
                    OPEN IN ARIBA
                  </span>
                </IconButton>
              ) : (
                ''
              )}
            </Grid>
          </Grid>

          <Dialog
            open={openCatalyst}
            maxWidth="xl"
            fullWidth
            style={{ overflow: 'hidden', maxWidth: '' }}
          >
            <div>
              <IconButton
                style={{ float: 'right' }}
                onClick={() => {
                  setOpenCatalyst(false)
                }}
              >
                <Close />
              </IconButton>
            </div>
            <div style={{ overflow: 'hidden' }}>
              <Catalyst Guid={selectedCatalyst ? selectedCatalyst.id : ''} />
            </div>
          </Dialog>

          <Grid container>
            <Grid container spacing={3}>
              {catalystList.main ? (
                <Grid item xs={12} style={{ margin: '10px' }}>
                  <span
                    className={classes.titleBar}
                    style={{ fontSize: '18px' }}
                  >
                    {mainAssesmentLabel}
                  </span>
                  {CatalystCard(catalystList.main)}
                </Grid>
              ) : (
                ''
              )}
              <Grid item xs={12} style={{ margin: '10px' }}>
                <Grid container spacing={3}>
                  {catalystList.list && catalystList.list.length > 0 ? (
                    <Grid item xs={12}>
                      <span
                        className={classes.titleBar}
                        style={{ fontSize: '18px' }}
                      >{`Assesments`}</span>
                    </Grid>
                  ) : (
                    ''
                  )}
                  {catalystList.list && catalystList.list.length > 0
                    ? catalystList.list.map((c, index) => {
                        return (
                          <Grid
                            key={index}
                            item
                            xs={getCatalystSize(catalystList.list.length)}
                          >
                            {CatalystCard(c)}
                          </Grid>
                        )
                      })
                    : ''}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
