import { useState, useEffect } from 'react'

// core components

import Snackbar from '../Snackbar/Snackbar'

const defaultNotificationsValues = {
  isOpenProp: false,
  color: 'info',
  message: 'Default Message',
  timeToClose: 2000,
  position: 'tl'
}
export function Notifications({
  isOpenProp,
  color,
  message,
  timeToClose,
  position
} = defaultNotificationsValues) {
  const [isOpen, setIsOpen] = useState(isOpenProp)

  useEffect(() => {
    if (!isOpenProp) {
      return
    }

    setIsOpen(isOpenProp)
  }, [isOpenProp])
  useEffect(() => {
    let timerId = null
    if (isOpen) {
      timerId = setTimeout(() => {
        setIsOpen(false)
      }, timeToClose)
    }
    // Specify how to clean up after this effect:
    return () => {
      if (timerId) {
        clearTimeout(timerId)
      }
    }
  }, [isOpen, timeToClose])
  return (
    <>
      <Snackbar
        place={position}
        color={color}
        message={message}
        open={isOpen}
        closeNotification={() => setIsOpen(false)}
        close
      />
    </>
  )
}
