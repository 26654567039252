import React from 'react'
import { useHistory } from 'react-router-dom'

// Core
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

// Style
import { makeStyles } from '@material-ui/core/styles'
import tenantsStyle from '../../assets/jss/views/Tenants'

// Icons
import Edit from '@material-ui/icons/Edit'
//API

//Component
import { Spinner } from '../../components/Loader/Spinner'
import { Notifications } from '../../components/Notification/Notification'

// Hooks
import { useGetTenants } from '../../hooks/useTenants'
import CustomTreeTable from '../../components/CustomTreeTable/CustomTreeTable'
const useStyles = makeStyles(tenantsStyle)

export default function Tenants () {
  const classes = useStyles()
  const history = useHistory()
  const columns = [
    { title: 'Id', field: 'tenantId' },
    { title: 'Name', field: 'name' },
    { title: 'City', field: 'city' },
    { title: 'Email', field: 'email' },
    { title: 'Status', field: 'status' }
  ]
  const { data, isLoading, error, isError, isFetching } = useGetTenants()

  function newTenant () {
    history.push('/admin/tenants/create')
  }

  return (
    <>
      {isLoading ? <Spinner /> : null}
      <Notifications
        isOpenProp={isError}
        message={isError ? error.response?.data?.message || error.message : ''}
        color="danger"
        timeToClose={6000}
        position="tr"
      />
      <h3 className={classes.h3}>Tenants{isFetching ? '...' : null}</h3>
      <Grid container justifycontent="center">
        <Grid item xs={12}>
          <CustomTreeTable
            rightButton={
              <Button
                style={{ width: '100px', float: 'right' }}
                className={classes.defaultPrimaryButton}
                onClick={() => newTenant()}
              >
                Add Tenant
              </Button>
            }
            columns={columns}
            data={data ? data : []}
            mainNode={['name']}
            treeTableWidth={800}
            actions={[
              {
                icon: () => <Edit />,
                tooltip: 'Edit Tenant',
                onClick: (event, rowData) => {
                  history.push(`/admin/tenants/edit/${rowData.tenantId}`)
                }
              }
            ]}
          />
        </Grid>
      </Grid>
    </>
  )
}
