import customSelectStyle from '../../customSelectStyle'
import customCheckboxRadioSwitch from '../../customCheckboxRadioSwitch'
import {
  primaryColor,
  primaryBoxShadow,
  whiteColor,
  blackColor,
  grayColor,
  hexToRgb,
  defaultFont
} from '../../../material-dashboard-pro-react'

const widgetFormStyle = theme => ({
  select: {
    padding: '12px 0 5px',
    fontSize: '0.88rem',
    fontWeight: '400',
    lineHeight: '1.42857',
    textDecoration: 'none',
    color: grayColor[2],
    letterSpacing: '0',
    '&:focus': {
      backgroundColor: 'transparent'
    },
    '&[aria-owns] + input + svg': {
      transform: 'rotate(180deg)'
    },
    '& + input + svg': {
      transition: 'all 300ms linear'
    },
    '&$disabled': {
      background: 'rgba(' + hexToRgb(grayColor[17]) + ', 0.2)'
    }
  },
  formControl: {
    width: '100%',
    margin: '0 0 17px 0',
    paddingTop: '27px',
    position: 'relative',
    verticalAlign: 'unset',
    '& svg,& .fab,& .far,& .fal,& .fas,& .material-icons': {
      color: grayColor[14]
    }
  },
  selectFormControl: {
    margin: '7px 0 17px 0 !important',
    '& > div': {
      '&:before': {
        borderBottomWidth: '1px !important',
        borderBottomColor: grayColor[4] + '!important'
      },
      '&:after': {
        borderBottomColor: primaryColor[0] + '!important'
      }
    }
  },
  selectLabel: {
    fontSize: '14px',
    color: grayColor[0] + ' !important',
    top: '8px'
  },
  inputLabel: {
    paddingBottom: '25px !important',
    color: 'rgba(' + hexToRgb(blackColor) + ', 0.40)',
    fontSize: '14px'
  },
  selectMenu: {
    '& > div > ul': {
      border: '0',
      padding: '5px 0',
      margin: '0',
      boxShadow: 'none',
      minWidth: '100%',
      borderRadius: '4px',
      boxSizing: 'border-box',
      display: 'block',
      fontSize: '14px',
      textAlign: 'left',
      listStyle: 'none',
      backgroundColor: whiteColor,
      backgroundClip: 'padding-box'
    },
    '& $selectPaper $selectMenuItemSelectedMultiple': {
      backgroundColor: 'inherit'
    },
    '& > div + div': {
      maxHeight: '266px !important'
    }
  },
  selectMenuItem: {
    fontSize: '13px',
    padding: '10px 20px',
    margin: '0 5px',
    borderRadius: '2px',
    transition: 'all 150ms linear',
    display: 'block',
    clear: 'both',
    fontWeight: '400',
    lineHeight: '2',
    whiteSpace: 'nowrap',
    color: grayColor[7],
    paddingRight: '30px',
    '&:hover': {
      backgroundColor: primaryColor[0],
      color: whiteColor,
      ...primaryBoxShadow
    }
  },
  displayTable: {
    display: 'table',
    overflow: 'hidden',
    transform: 'translateY(50%)'
  },
  verticalAlign: {
    display: 'table-cell',
    verticalAlign: 'middle'
  },
  centerVertically: {
    transform: 'translateY(50%)'
  },
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {
    color: '#555'
  },
  inputAdornment: {
    position: 'relative'
  },
  activeToggle: {
    marginLeft: '0px'
  },
  documentation: {
    float: 'right',
    textTransform: 'none'
  },
  socialButtonsIcons: {
    fontSize: '18px',
    marginTop: '-2px',
    position: 'relative'
  },
  sectionTitle: {
    fontWeight: 'bold',
    textTransform: 'none'
  },
  contentLabelHorizontal: {
    color: 'rgba(' + hexToRgb(blackColor) + ', 0.40)',
    fontSize: '14px',
    paddingTop: '50px'
  },
  inputLabelHorizontal: {
    color: 'rgba(' + hexToRgb(blackColor) + ', 0.40)',
    display: 'inline-flex',
    fontSize: '14px',
    '@media (min-width: 992px)': {
      paddingTop: '30px'
    }
  },
  toggleTitle: {
    color: 'rgba(' + hexToRgb(blackColor) + ', 0.40)',
    fontSize: '14px'
  },
  row: {
    paddingTop: '20px'
  },
  inputDisabled: {
    background: 'gray'
  },
  customDisabled: {
    '&$disabled': {
      background: 'rgba(' + hexToRgb(grayColor[17]) + ', 0.2)'
    }
  },
  disabled: {},
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  cardTitle: {
    marginTop: '0',
    marginBottom: '3px',
    color: grayColor[2],
    fontSize: '18px'
  },
  cardHeader: {
    zIndex: '3'
  },
  cardSubtitle: {
    ...defaultFont,
    color: grayColor[0],
    fontSize: '14px',
    margin: '0 0 10px'
  },
  center: {
    textAlign: 'center'
  },
  right: {
    textAlign: 'right'
  },
  left: {
    textAlign: 'left'
  },
  marginRight: {
    marginRight: '5px'
  },
  modalSectionTitle: {
    marginTop: '30px'
  },
  appRow: {
    paddingTop: '20px',
    borderBottom: grayColor[0] + '!important',
    '& > div': {
      '&:before': {
        borderBottomWidth: '1px !important',
        borderBottomColor: grayColor[4] + '!important'
      },
      '&:after': {
        backgroundColor: grayColor[0] + '!important',
        borderBottomColor: grayColor[0] + '!important'
      }
    }
  },
  addButton: {
    marginTop: '-25px',
    float: 'right'
  },
  disabledApp: {
    backgroundColor: 'rgba(0, 0, 0, 0.09)'
  }
})

export default widgetFormStyle
