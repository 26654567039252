import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//Icons
import { Button, Grid } from '@material-ui/core'
import AssignBvDId from './AssignBvDId'
import { env } from '../../utils/Constants'

import { Spinner } from '../../components/Loader/Spinner'
import CustomTreeTable from '../../components/CustomTreeTable/CustomTreeTable'
import usersEditStyle from '../../assets/jss/views/UsersEdit'
import SearchIcon from '@material-ui/icons/Search'

// Api Calls
import apiApps from '../../services/apiApps'
import apiAppsProd from '../../services/apiAppsProd'
const getMissingBvDSupplierwithCallback =
  env === 'local'
    ? apiApps.getMissingBvDSupplierwithCallback
    : apiAppsProd.getMissingBvDSupplierwithCallback

const useStyles = makeStyles(usersEditStyle)

export default function AssignBvDIdTable (props) {
  const [missingData, setMissingData] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const isMounted = useRef(false)
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      getMissingBvDSupplierwithCallback(missing => {
        setMissingData(missing)
        setIsLoading(false)
      })
    } else {
    }
    // this function will be ran when the component is re-renderd or unmounted
    return () => {}
    //you need to add in this array the properties that need to be checked for changes and the ones you will be using in this function
    //, if one property changes, the useEffect function will be ran again (once per DOM change)
    // you can send all the props and if something updated this function will be ran
  })

  const classes = useStyles()
  const [supplier, setSupplier] = React.useState()
  const [showAssign, setShowAssign] = React.useState(false)

  let tableData = []
  if (missingData && missingData.data) {
    if (missingData.data.length > 0) {
      missingData.data.forEach(m => {
        tableData.push({
          supplierId: m.supplierId,
          supplierName: m.supplierName,
          assign: (
            <Button
              style={{ width: '250px', margin: 0, float: 'right' }}
              className={classes.defaultButton}
              onClick={() => {
                setShowAssign(true)
                setSupplier(m)
              }}
            >
              <span>
                <SearchIcon style={{ display: 'block', margin: 'auto' }} />
              </span>
              <span>Search and Link</span>
            </Button>
          )
        })
      })
    }
  }

  const columns = [
    { title: 'Supplier Id', field: 'supplierId' },
    { title: 'Supplier Name', field: 'supplierName' },
    { title: '', field: 'assign' }
  ]

  if (showAssign) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <AssignBvDId
            setOnConfirmModalFunction={message => {
              props.setValidationMessage(message)
              props.setSubmitSuccess(true)
              setTimeout(() => {
                window.location.reload()
              }, 1500)
            }}
            onSuccess={(missing, message) => {
              //setMissingData(missing)
              setShowAssign(false)
              props.setValidationMessage(message)
              props.setSubmitSuccess(true)
              setTimeout(() => {
                window.location.reload()
              }, 1500)
            }}
            history={props.history}
            supplier={supplier}
            backButtonOnClick={() => {
              setShowAssign(false)
              setSupplier(undefined)
            }}
            {...props}
          />
        </Grid>
      </Grid>
    )
  } else
    return (
      <Grid container>
        <h3>Link Suppliers to BVD ID's</h3>
        {isLoading ? <Spinner /> : ''}
        <Grid item xs={12}>
          <CustomTreeTable
            searchPlaceHolder={'Find Supplier'}
            defaultOrderBy={'Score'}
            defaultOrder={'desc'}
            columns={columns}
            data={tableData}
            mainNode={['supplierName']}
            treeTableWidth={600}
          />
        </Grid>
        <Grid></Grid>
      </Grid>
    )
}
