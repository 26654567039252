import { primaryColor } from '../Colors'
const errorTenant = theme => ({
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
    // border: '3px solid green',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  notFound: {
    position: 'relative',
    height: '220px'
  },
  h1: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    '-webkit-transform': 'translate(-50%, -50%)',
    ' -ms-transform': 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
    fontSize: '186px',
    fontWeight: '200',
    margin: '0px',
    background: primaryColor,
    color: 'transparent',
    '-webkit-background-clip': 'text',
    backgroundClip: 'text',
    textTransform: 'uppercase'
  },
  h2: {
    fontSize: '33px',
    fontWeight: '200',
    textTransform: 'uppercase',
    marginTop: '0px',
    marginBottom: '25px',
    letterSpacing: '3px'
  },
  p: {
    fontSize: '16px',
    fontWeight: '200',
    marginTop: '0px',
    marginBottom: '25px'
  }
})

export default errorTenant
