import defaultStyle from '../Default'

const usersEditStyle = {
  ...defaultStyle,
  card: {
    ...defaultStyle.card,
    width: '100%',
    height: '100%'
  },
  defaultButton: {
    ...defaultStyle.defaultButton,
    width: '10%',
    marginLeft: '0',
    marginTop: '20px'
  },
  textField: {
    ...defaultStyle.textField,
    width: '90%',
    margin: '20px 10px 20px 10px'
  },
  textFieldError: {
    ...defaultStyle.textField,
    width: '90%',
    margin: '20px 10px 20px 10px',
    backgroundColor: 'rgb(255, 0, 252)'
  },
  selectField: {
    ...defaultStyle.selectField,
    width: '90%',
    margin: '20px 10px 20px 10px'
  }
}

export default usersEditStyle
