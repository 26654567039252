import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'

import { getIamService } from '../../../apis/iam'
import { UserForm } from '../../../components/Forms/UserForm'
import { Notifications } from '../../../components/Notification/Notification'
import { Spinner } from '../../../components/Loader/Spinner'
import { useAuth } from '../../../Providers/Auth'
import { useGetTenants } from '../../../hooks/useTenants'
import { useGetRoles } from '../../../hooks/useGetRoles'

export default function UsersEdit () {
  const history = useHistory()
  const { userId, tenantId } = useParams()
  let msgError = ''
  const queryClient = useQueryClient()
  const { roles, tenant } = useAuth()
  const getTenants = useGetTenants()
  const getRoles = useGetRoles()
  const getUser = useQuery(
    ['users', tenantId, userId],
    () =>
      getIamService()
        .get(`/tenants/${tenantId}/users/${userId}`)
        .then(result => {
          const newData = {
            ...result.data,
            role: result.data.roles[0],
            enabled: result.data.enabled ? 'Enabled' : 'Disabled',
            password: ''
          }
          delete newData['roles']
          return newData
        }),
    {
      staleTime: Infinity,
      enabled: !!getTenants.data
    }
  )

  const updateUser = useMutation(
    values => getIamService().put(`/users/${userId}`, values),
    {
      onMutate: async newUser => {
        const userFormatted = {
          ...newUser,
          role: newUser.roles[0]
        }
        delete userFormatted['roles']
        await queryClient.cancelQueries([
          'users',
          userFormatted.tenantId,
          userFormatted.userId
        ])

        // Snapshot the previous value
        const previousUser = queryClient.getQueryData([
          'users',
          userFormatted.tenantId,
          userFormatted.userId
        ])

        // Optimistically update to the new value
        queryClient.setQueryData(
          ['users', userFormatted.tenantId, userFormatted.userId],
          userFormatted
        )

        const previousUserList = queryClient.getQueryData('users')

        if (previousUserList) {
          queryClient.setQueryData(['users'], oldUsers => {
            return oldUsers.map(user => {
              if (user.userId === userFormatted.userId) {
                return userFormatted
              }
              return user
            })
          })
        }

        // Return a function with the snapshotted value
        return () => {
          queryClient.setQueryData(
            ['users', userFormatted.tenantId, userFormatted.userId],
            previousUser
          )
          queryClient.setQueryData(['users'], previousUserList)
        }
      }
    }
  )

  function saveUser (userData) {
    const data = {
      ...userData,
      roles: [userData.role],
      enabled: true
    }
    delete data['role']
    updateUser.mutate(data, {
      onSuccess: () => history.push('/admin/users'),
      onSettled: () => {
        queryClient.invalidateQueries('users')
        queryClient.invalidateQueries(['users', data.tenantId, data.userId])
      }
    })
  }

  if (updateUser.isError) {
    msgError =
      updateUser.error.response?.data?.message || updateUser.error.message
  } else if (getUser.isError) {
    msgError = getUser.error.response?.data?.message || getUser.error.message
  } else if (getTenants.isError) {
    msgError =
      getTenants.error.response?.data?.message || getTenants.error.message
  }

  const isError = updateUser.isError || getUser.isError || getTenants.isError
  const isLoading =
    updateUser.isLoading || getUser.isLoading || getTenants.isLoading

  return (
    <>
      {isLoading ? <Spinner /> : null}
      <Notifications
        isOpenProp={isError}
        message={msgError}
        color="danger"
        timeToClose={6000}
        position="tr"
      />

      <UserForm
        edit={true}
        initialValues={getUser.data}
        onSubmit={saveUser}
        submitText={
          updateUser.isLoading
            ? 'Saving...'
            : updateUser.isError
            ? 'Error!'
            : 'Save User'
        }
        emailEnabled={true}
        tenants={
          roles
            ? roles === 'superAdmin'
              ? getTenants.data
              : [{ tenantId: tenant }]
            : ''
        }
        roles={
          getRoles.data
            ? getRoles.data
            : [
                { roleId: 'superAdmin', name: 'Super Admin' },
                { roleId: 'tenantAdmin', name: 'Tenant Admin' },
                { roleId: 'userBvd', name: 'User Bvd' }
              ]
        }
      />
    </>
  )
}
