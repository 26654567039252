import axios from 'axios'
import { signOut } from '../layout/Admin/Admin'
import { getTenant } from '../Providers/Auth'

const getAccessToken = function () {
  const authConfigLS = localStorage.getItem(
    `CognitoIdentity.Configure.${getTenant()}`
  )
  if (!authConfigLS) {
    signOutAsyncCall()
    return
  }
  const authConfig = JSON.parse(authConfigLS)

  if (!authConfig) {
    // signOutAsyncCall()
    return
  } else {
    const userEmail = localStorage.getItem(
      `CognitoIdentityServiceProvider.${authConfig.Auth.userPoolWebClientId}.LastAuthUser`
    )
    return (
      'Bearer ' +
      localStorage.getItem(
        `CognitoIdentityServiceProvider.${authConfig.Auth.userPoolWebClientId}.${userEmail}.accessToken`
      )
    )
  }
}
export function getIamService () {
  const authorization = getAccessToken()
  return axios.create({
    baseURL: process.env.REACT_APP_API_ADMIN_PROD,
    headers: {
      'Content-Type': 'application/json',
      Authorization: authorization
    },
    validateStatus: status => {
      console.log(status)
      if (status === 401) {
        signOutAsyncCall()
        return true
      }

      return true
    }
  })
}
export function getIamService2 () {
  debugger
  const authorization = getAccessToken()
  return axios.create({
    baseURL: process.env.REACT_APP_API_IAM_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: authorization
    },
    validateStatus: async status => {
      console.log(status)
      if (status === 401) {
        await signOutAsyncCall()
        return true
      }

      return true
    }
  })
}

async function signOutAsyncCall () {
  await signOut()
}
