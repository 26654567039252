import React, { useEffect, useRef } from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// import { default as CustomTable } from '../../components/Table/Table.js'
import Card from '../../components/Card/Card.js'
import { cloneObject } from '../../utils/functions'
// Icons
import AddCircleIcon from '@material-ui/icons/AddCircle'
// import CancelIcon from '@material-ui/icons/Cancel'
import styles from '../../assets/jss/layout/Admin'
import MappingComponent from '../MappingComponent/MappingComponentView'

import CheckIcon from '@material-ui/icons/Check'
// import a from '../MappingComponent/types'
import { Button, Grid, IconButton } from '@material-ui/core'
import { env } from '../../utils/Constants'

import CustomModal from '../../components/CustomModal/CustomModal.js'
import CustomTreeTable from '../../components/CustomTreeTable/CustomTreeTable.js'
import ParametersAccordion from '../CustomGenericViews/ParametersAccordion.js'
import { Spinner } from '../../components/Loader/Spinner.js'
import { ArrowBack } from '@material-ui/icons'

// Api Calls
import apiApps from '../../services/apiApps.js'
import apiAppsProd from '../../services/apiAppsProd'
const getMappingsAndLists =
  env === 'local'
    ? apiApps.getMappingsAndLists
    : apiAppsProd.getMappingsAndLists
const setTenantMappings =
  env === 'local' ? apiApps.setTenantMappings : apiAppsProd.setTenantMappings
const updateCatalystJob =
  env === 'local' ? apiApps.updateCatalystJob : apiAppsProd.updateCatalystJob
const updateOrbisJob =
  env === 'local' ? apiApps.updateOrbisJob : apiAppsProd.updateOrbisJob
const getCatalystModelsWithCallBack =
  env === 'local'
    ? apiApps.getCatalystModelsWithCallBack
    : apiAppsProd.getCatalystModelsWithCallBack

const useStyles = makeStyles(styles)
const orbisSearchMappingType = 'orbisSearchMapping'
const questionnaireMappingType = 'questionnaireMapping'
const catalystMappingType = 'catalystMapping'
const riskMappingType = 'riskMapping'

export default function Mapping (props) {
  const [isLoading, setIsLoading] = React.useState(true)
  //  const [lists, setLists] = React.useState([])

  const [jobSettings, setJobSettings] = React.useState(undefined)
  const [userMapping, setUserMapping] = React.useState([])
  //const [orbisListsErrorMessage, setOrbisListsErrorMessage] = React.useState('')
  const isMounted = useRef(false)
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      getMappingsAndLists(props.type, (dataMapping, job) => {
        //setOrbisListsErrorMessage(listError)
        setUserMapping(dataMapping)
        if (props.type === catalystMappingType) {
          getCatalystModelsWithCallBack(job, models => {
            if (Array.isArray(models) && models.length === 0)
              job.catalystModel.options = [{}]
            else job.catalystModel.options = models

            setJobSettings(job)
            setIsLoading(false)
          })
        } else {
          setJobSettings(job)
          setIsLoading(false)
        }
      })
    } else {
    }
    // this function will be ran when the component is re-renderd or unmounted
    return () => {}
    //you need to add in this array the properties that need to be checked for changes and the ones you will be using in this function
    //, if one property changes, the useEffect function will be ran again (once per DOM change)
    // you can send all the props and if something updated this function will be ran
  })

  const [
    showQuestionnaireMapping,
    setShowQuestionnaireMapping
  ] = React.useState(null)

  const [showOrbisSearchMapping, setShowOrbisSearchMapping] = React.useState(
    null
  )
  const [showCatlystMapping, setShowCatlystMapping] = React.useState(null)
  const [showRiskMapping, setShowRiskMapping] = React.useState(null)

  const [mappingVersion, setMappingVersion] = React.useState(undefined)
  const [, setVersionSelect] = React.useState(undefined)
  const [showModal, setShowModal] = React.useState(false)
  const [userBackupMapping, setUserBackupMapping] = React.useState([])
  const [onConfirmModalFunction, setOnConfirmModalFunction] = React.useState({
    function: () => {}
  })
  const [modalMessage, setModalMessage] = React.useState('')

  const [questionnaireMapping, setQuestionnaireMapping] = React.useState([])

  const [
    mainQuestionnaireOptions,
    setMainQuestionnaireOptions
  ] = React.useState([
    'addressLine1',
    'addressLine2',
    'bvdId',
    'city',
    'countryIsoCode',
    'state',
    'email',
    'entityType',
    'incorporationDate',
    'nace2MainSection',
    'nace2PrimaryCode',
    'nace2PrimaryLabel',
    'name',
    'nationalId',
    'nationalIdLabel',
    'nationalIdType',
    'originalCurrency',
    'postcode',
    'standardisedLegalForm',
    'status',
    'tin',
    'tradeDescriptionEn',
    'tradeDescriptionLanguage',
    'custom1',
    'custom2',
    'custom3',
    'custom4',
    'custom5',
    'custom6',
    'custom7',
    'custom8',
    'custom9',
    'custom10'
  ])

  const [catalystOptions] = React.useState([
    { label: 'Catalyst Status', value: 'CF_RA_Status' },
    { label: 'Score', value: 'CF_RA_Score' },
    { label: 'Score Label', value: 'CF_RA_ScoreKey.CF_ScoreAndClasses_Label' },
    { label: 'Started Date', value: 'CF_RA_StartedOn' },
    { label: 'Modified Date', value: 'CFE_RA_MODIFICATION_DATE' },
    {
      label: 'Connector URL',
      value: 'connectorUrl',
      custom: true
    },
    {
      label: 'Connector Status',
      value: 'bvdStatus',
      custom: true
    }
  ])
  const [mainOrbisMappingOptions, setMainOrbisMappingOptions] = React.useState(
    props.type === riskMappingType
      ? [
          'FIELD_01',
          'FIELD_02',
          'FIELD_03',
          'FIELD_04',
          'FIELD_05',
          'FIELD_06',
          'FIELD_07',
          'FIELD_08',
          'FIELD_09',
          'FIELD_10',
          'FIELD_11',
          'FIELD_12',
          'FIELD_13',
          'FIELD_14'
        ]
      : [
          'supplierName',
          'supplierAddress',
          'city',
          'custom1',
          'custom2',
          'custom3',
          'custom4',
          'custom5',
          'custom6',
          'custom7'
        ]
  )

  const classes = useStyles()
  async function saveJobSettings (type) {
    setIsLoading(true)

    if (type === questionnaireMappingType) {
      let newSettings = await updateOrbisJob({ ...jobSettings })
      if (newSettings.success) {
        props.setValidationMessage('Success')
        props.setSubmitSuccess(true)
      }
    }
    if (type === catalystMappingType) {
      let newSettings = await updateCatalystJob({ ...jobSettings })
      if (newSettings.success) {
        props.setValidationMessage('Success')
        props.setSubmitSuccess(true)
      }
    }
    setIsLoading(false)
  }

  function getMappingVersions (type) {
    let tableData = []
    let mapping = userMapping[type]
    if (mapping && mapping.mappings) {
      const keys = Object.keys(mapping.mappings)
      if (keys && keys.length > 0) {
        keys.forEach(key => {
          let element = mapping.mappings[key]
          tableData.push({
            date: element.date,
            version: key,

            edit: (
              <Button
                style={{ float: 'none', width: '50%', textAlign: 'center' }}
                className={classes.defaultButton}
                onClick={() => {
                  setUserBackupMapping(cloneObject(userMapping))
                  setShowCatlystMapping(type === catalystMappingType)
                  setShowQuestionnaireMapping(type === questionnaireMappingType)
                  setShowRiskMapping(type === riskMappingType)
                  setShowOrbisSearchMapping(type === orbisSearchMappingType)
                  setMappingVersion(key)
                  if (type !== catalystMappingType)
                    setVersionSelect(element.select.SELECT)
                }}
              >
                Edit
              </Button>
            ),
            active:
              key === mapping.active ? (
                <CheckIcon></CheckIcon>
              ) : (
                <Button
                  style={{ float: 'none', width: '50%', textAlign: 'center' }}
                  className={classes.defaultButton}
                  onClick={async () => {
                    let newMapping = cloneObject(userMapping)
                    newMapping[type].active = key
                    setUserMapping(newMapping)
                    setIsLoading(true)
                    let response = await setTenantMappings(newMapping)
                    setIsLoading(false)
                    if (response && response.success) {
                      props.setValidationMessage('Success')
                      props.setSubmitSuccess(true)
                      setOnConfirmModalFunction({ function: () => {} })

                      //props.backFunction()
                    } else {
                      // alert(response.message)
                      props.setValidationMessage(response.message)
                      props.setSubmitSuccess(true)

                      setOnConfirmModalFunction({ function: () => {} })
                    }
                  }}
                >
                  ACTIVATE
                </Button>
              )
          })
        })
      }
    }
    //console.log(jobSettings)
    return (
      <Card>
        <Grid container>
          <Grid item xs={12}>
            <IconButton
              onClick={() => {
                props.history.push(`/admin/configurations`)
              }}
            >
              <ArrowBack />
            </IconButton>
            <Grid container>
              <Grid item xs={6} style={{ paddingLeft: '15px' }}>
                <span style={{ fontSize: '1.5vmax', fontWeight: 'bold' }}>
                  {type === questionnaireMappingType
                    ? 'Orbis Integration Mapping'
                    : type === catalystMappingType
                    ? 'Catalyst Assesment Integration Mapping'
                    : type === riskMappingType
                    ? 'Risk Integration Mapping'
                    : type === orbisSearchMappingType
                    ? 'Ariba Custom Form Integration Mapping'
                    : ''}
                </span>
              </Grid>
              <Grid item xs={6}>
                <IconButton
                  style={{ float: 'right', height: '1vmax' }}
                  onClick={() => {
                    setShowCatlystMapping(type === catalystMappingType)
                    setShowRiskMapping(type === riskMappingType)
                    setShowQuestionnaireMapping(
                      type === questionnaireMappingType
                    )
                    setShowOrbisSearchMapping(type === orbisSearchMappingType)
                    setMappingVersion(undefined)
                  }}
                >
                  <div>
                    <AddCircleIcon />
                    <span
                      style={{
                        fontSize: '1vmax',
                        fontWeight: 'bold',
                        float: 'right'
                      }}
                    >
                      {'Add New Mapping'}
                    </span>
                  </div>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CustomTreeTable
              data={tableData}
              columns={[
                { title: 'Date', field: 'date' },
                { title: 'Version', field: 'version' },
                { title: 'Edit', field: 'edit' },
                { title: 'Active', field: 'active' }
              ]}
              options={{
                actionsColumnIndex: -1,
                sorting: true,
                showTitle: false
              }}
            />
          </Grid>
          {jobSettings ? (
            <Grid item xs={12}>
              <ParametersAccordion
                initialState={{
                  forceAccordion: true,
                  parameters: [
                    {
                      initialOpen: true,
                      title: jobSettings.title,
                      saveButton: isValidated => {
                        return (
                          <div style={{ width: '100%' }}>
                            <div style={{ float: 'right' }}>
                              <Grid style={{ float: 'right', margin: '10px' }}>
                                <Button
                                  style={{ width: '200px' }}
                                  className={classes.defaultButton}
                                  onClick={() => {
                                    if (typeof isValidated === 'function') {
                                      if (isValidated()) saveJobSettings(type)
                                    } else saveJobSettings(type)
                                  }}
                                >
                                  SAVE
                                </Button>
                              </Grid>
                              {type === catalystMappingType ? (
                                <Grid
                                  style={{ float: 'right', margin: '10px' }}
                                >
                                  <Button
                                    style={{ width: '200px' }}
                                    className={classes.defaultButton}
                                    onClick={() => {
                                      setIsLoading(true)
                                      getCatalystModelsWithCallBack(
                                        jobSettings,
                                        models => {
                                          let job = jobSettings
                                          if (
                                            Array.isArray(models) &&
                                            models.length === 0
                                          )
                                            job.catalystModel.options = [{}]
                                          else
                                            job.catalystModel.options = models
                                          setJobSettings(job)
                                          setIsLoading(false)
                                        }
                                      )
                                    }}
                                  >
                                    Refresh Models
                                  </Button>
                                </Grid>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        )
                      },
                      conectivityMapping: {
                        env: '',
                        app: 'slp',
                        service: 'ariba',
                        hideServiceName: true,
                        hideTestConnection: true,
                        fields: jobSettings
                      }
                    }
                  ]
                }}
              ></ParametersAccordion>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </Card>
    )
  }

  function getMappingComponent (
    type,
    setMapping,
    setMainOptions,
    mappingMainOptions,
    options = []
  ) {
    //let options = translateSelectFields(teeest, select)

    // let mainOptions = [
    //   'supplierName',
    //   'supplierAddress',
    //   'city',
    //   'custom1',
    //   'custom2',
    //   'custom3',
    //   'custom4',
    //   'custom5',
    //   'custom6',
    //   'custom7'
    // ]

    // let mapping = {
    //   supplierName: { mapping: [{ value: 'NAME', label: 'NAME' }] },
    //   supplierAddress: {
    //     mapping: [{ value: 'ADDRESS_LINE1', label: 'ADDRESS_LINE1' }]
    //   },
    //   city: { mapping: [{ value: 'CITY', label: 'CITY' }] }
    // }

    return (
      <Grid container justifyContent="center">
        <Card style={{ padding: '100px', paddingTop: '0px' }}>
          <Grid item xs={12}>
            <MappingComponent
              //orbisListsErrorMessage={orbisListsErrorMessage}
              setShowModal={setShowModal}
              setOnConfirmModalFunction={setOnConfirmModalFunction}
              setModalMessage={setModalMessage}
              userMapping={userMapping}
              mappingVersion={mappingVersion}
              type={type}
              isQuestionnaireMapping={
                type === questionnaireMappingType ||
                type === catalystMappingType
              }
              setMapping={setMapping}
              setMainOptions={setMainOptions}
              mainOptions={mappingMainOptions}
              options={type === catalystMappingType ? catalystOptions : options}
              backFunction={refresh => {
                if (refresh) setUserMapping(cloneObject(userBackupMapping))
                setShowQuestionnaireMapping(false)
                setShowRiskMapping(false)
                setShowCatlystMapping(false)
                setShowOrbisSearchMapping(false)
                setMappingVersion(undefined)
                setVersionSelect([])
              }}
              //lists={lists}
              title={
                type === questionnaireMappingType
                  ? 'Questionnaire Mapping'
                  : type === orbisSearchMappingType
                  ? 'Orbis Search Mapping'
                  : type === catalystMappingType
                  ? 'Catalyst Ariba Mapping'
                  : type === riskMappingType
                  ? 'Ariba Risk Mapping'
                  : ''
              }
              mapping={questionnaireMapping}
              {...props}
            ></MappingComponent>
          </Grid>
        </Card>
      </Grid>
    )
  }

  return (
    <div>
      <div>
        <Grid container>
          {showModal ? (
            <CustomModal
              message={modalMessage}
              //title={'Alert'}
              confirmBtnCssClass={classes.defaultButton}
              cancelBtnCssClass={classes.buttonDanger}
              onCancel={() => {
                setShowModal(false)
                setModalMessage('')
                setOnConfirmModalFunction({ function: () => {} })
              }}
              onConfirm={() => {
                setShowModal(false)
                setModalMessage('')
                onConfirmModalFunction.function()
              }}
            ></CustomModal>
          ) : (
            ''
          )}
          {isLoading ? <Spinner /> : ''}
          {showOrbisSearchMapping
            ? getMappingComponent(
                orbisSearchMappingType,
                showOrbisSearchMapping,
                setMainOrbisMappingOptions,
                mainQuestionnaireOptions
              )
            : showQuestionnaireMapping
            ? getMappingComponent(
                questionnaireMappingType,
                setQuestionnaireMapping,
                setMainQuestionnaireOptions,
                mainOrbisMappingOptions
              )
            : showCatlystMapping
            ? getMappingComponent(
                catalystMappingType,
                setQuestionnaireMapping,
                setMainQuestionnaireOptions,
                mainOrbisMappingOptions
              )
            : showRiskMapping
            ? getMappingComponent(
                riskMappingType,
                setQuestionnaireMapping,
                setMainQuestionnaireOptions,
                mainOrbisMappingOptions
              )
            : getMappingVersions(props.type)}
        </Grid>
      </div>
    </div>
  )
}
