import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'

import { Grid } from '@material-ui/core'
export default function CustomPopover (props) {
  const [open, setOpen] = useState(false)
  const [closing, setClosing] = useState(false)

  const divRef = React.useRef()
  function handleClick () {
    setOpen(true)
  }

  return (
    <div ref={divRef}>
      <ListItem
        button
        className={props.className}
        onClick={handleClick}
        onMouseLeave={e => {
          if (!closing)
            setTimeout(() => {
              setOpen(false)
              setClosing(true)
              setTimeout(() => {
                setClosing(false)
              }, 500)
            }, 500)
        }}
      >
        <div>{props.image}</div>

        <ListItemIcon
          style={{ width: '100%', display: open ? 'block' : 'none' }}
        >
          <Grid container style={{ height: '41px', width: '0px' }}>
            <div
              style={{
                borderRadius: 0,
                marginLeft: '13px',
                zIndex: 800,
                backgroundColor: 'white'
              }}
            >
              <Box
                style={{ padding: '0px' }}
                onMouseLeave={e => {
                  if (!closing)
                    setTimeout(() => {
                      setOpen(false)
                      setClosing(true)
                      setTimeout(() => {
                        setClosing(false)
                      }, 500)
                    }, 500)
                }}
                onMouseEnter={e => {
                  if (closing) {
                    setOpen(true)
                    setTimeout(() => {
                      setOpen(true)
                    }, 100)
                    setTimeout(() => {
                      setOpen(true)
                    }, 250)
                  } else
                    setTimeout(() => {
                      setOpen(true)
                    }, 500)
                }}
                p={2}
              >
                <div
                  style={{
                    transition: 'max-height 0.9s ease-in',
                    border: '#030f59',
                    borderBottomStyle: 'solid',
                    borderRightStyle: 'solid'
                  }}
                >
                  {props.subMenu}
                </div>
              </Box>
            </div>
          </Grid>
        </ListItemIcon>
      </ListItem>
    </div>
  )
}
