import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import SearchIcon from '@material-ui/icons/Search'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import TableHead from '@material-ui/core/TableHead'
import { Grid } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'
import TableFooter from '@material-ui/core/TableFooter'
import { primaryColor } from '../../assets/jss/Colors'
const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },

  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}))

function TablePaginationActions (props) {
  const classes = useStyles()
  const theme = useTheme()

  const { count, page, rowsPerPage, onPageChange, onChangePage } = props

  const handleFirstPageButtonClick = event => {
    if (onChangePage) onChangePage(event, 0)
    else if (onPageChange) onPageChange(event, 0)
  }

  const handleBackButtonClick = event => {
    if (onChangePage) onChangePage(event, page - 1)
    else if (onPageChange) onPageChange(event, page - 1)
  }

  const handleNextButtonClick = event => {
    if (onChangePage) onChangePage(event, page + 1)
    else if (onPageChange) onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = event => {
    if (onChangePage)
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    else if (onPageChange)
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
}

/*const useStyles2 = makeStyles(props => {
  return {
    icon: {
      color: `${
        props.headerBackgroundColor ? props.headerColor : 'white'
      } !important`
    }
  }
})*/

function EnhancedTableHead (props) {
  const useStylesHead = makeStyles({
    icon: {
      color: `${props.textColor ? props.textColor : 'white'} !important`
    }
  })
  const { order, orderBy, onRequestSort, columns, actions } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }
  const classes = useStylesHead()

  return (
    <TableHead style={{ borderRadius: '25px' }}>
      <TableRow>
        {columns.map((headCell, index) => (
          <TableCell
            key={index}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.field ? order : false}
            style={{
              background: props.background,
              borderRadius:
                index === 0
                  ? '10px 0px 0px 0px'
                  : index === columns.length - 1 && !actions
                  ? '0px 10px 0px 0px'
                  : '0px'
            }}
          >
            <TableSortLabel
              classes={{ icon: classes.icon }}
              style={{ color: props.textColor }}
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : 'asc'}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.title}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {actions ? (
          <TableCell
            style={{
              color: props.textColor,
              background: props.background,
              borderRadius: '0px 10px 0px 0px'
            }}
            align={'center'}
          >
            Actions
          </TableCell>
        ) : (
          undefined
        )}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}
export default function CustomPaginationActionsTable (props) {
  //count, page, rowsPerPage, onPageChange
  //const classes = useStyles2(props)
  const [page, setPage] = React.useState(0)
  const [searchFilter, setSearchFilter] = React.useState('')
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [order, setOrder] = React.useState(
    props.defaultOrder ? props.defaultOrder : 'asc'
  )
  const [orderBy, setOrderBy] = React.useState(
    props.defaultOrderBy
      ? props.defaultOrderBy
      : props.noInitialOrder
      ? ''
      : props.columns[0].field
  )

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const filter = () => {
    let data = []
    if (props.data)
      props.data.forEach(element => {
        let keys = Object.keys(element)
        for (let index = 0; index < keys.length; index++) {
          const key = keys[index]
          if (
            typeof element[key] === 'string' &&
            element[key].toLowerCase().includes(searchFilter.toLowerCase())
          ) {
            data.push(element)
            break
          }
        }
      })
    return data.sort(function (a, b) {
      let aValue = a[orderBy]
      let bValue = b[orderBy]
      if (typeof aValue === 'string' && aValue[aValue.length - 1] === '%')
        aValue = aValue.substring(0, aValue.length - 2)
      if (typeof bValue === 'string' && bValue[bValue.length - 1] === '%')
        bValue = bValue.substring(0, bValue.length - 2)
      let orderA = parseInt(aValue)
      if (!parseInt(aValue)) {
        orderA = false
      }
      let orderB = parseInt(bValue)
      if (!parseInt(bValue)) {
        orderB = false
      }
      if (orderA && orderB)
        if (order === 'asc') return orderA - orderB
        else return orderB - orderA
      else {
        if (aValue > bValue)
          if (order === 'asc') return -1
          else return 1
        else {
          if (order === 'asc') return 1
          else return -1
        }
      }
    })
  }
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  let data = filter()
  let columns = []
  if (props.columns) {
    for (let index = 0; index < props.columns.length; index++) {
      props.columns[index].id = index
      columns.push(props.columns[index])
    }
  }
  const length = data ? data.length : 0
  const emptyRows = props.minSize
    ? 0
    : rowsPerPage - Math.min(rowsPerPage, length - page * rowsPerPage)
  function getActionLength (row) {
    let length = 0
    if (props.actions) {
      props.actions.forEach(actionFunction => {
        let action = actionFunction
        if (typeof actionFunction === 'function') action = actionFunction(row)
        if (action.icon(row)) length++
      })
    }
    return length
  }
  return (
    <TableContainer>
      {!props.hideSearch ? (
        <Grid container>
          <Grid
            item
            xs={props.rightButton ? 4 : 6}
            md={props.rightButton ? 5 : 6}
            lg={6}
            style={{ padding: '20px' }}
          >
            {props.tableTitle}
          </Grid>
          <Grid
            item
            xs={props.rightButton ? 4 : 6}
            md={props.rightButton ? 5 : 6}
            style={{ display: 'block', margin: 'auto' }}
          >
            <TextField
              placeholder={
                props.searchPlaceHolder ? props.searchPlaceHolder : 'Search'
              }
              style={{ float: 'right', margin: '10px', width: '100%' }}
              value={searchFilter}
              onChange={e => {
                setSearchFilter(e.target.value)
              }}
              InputProps={{
                style: { height: '35px', width: '100%' },
                endAdornment: <SearchIcon />
              }}
              variant="outlined"
            ></TextField>
          </Grid>
          {props.rightButton ? (
            <Grid
              item
              xs={2}
              lg={1}
              style={{ display: 'block', margin: 'auto' }}
            >
              <div>{props.rightButton}</div>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      ) : !props.rightButton ? (
        <div>{props.rightButton}</div>
      ) : (
        ''
      )}
      <Paper>
        <Table>
          <EnhancedTableHead
            textColor={
              props.headerBackgroundColor
                ? props.headerBackgroundColor
                : 'white'
            }
            background={
              props.headerBackgroundColor
                ? props.headerBackgroundColor
                : primaryColor
            }
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            columns={columns}
            actions={props.actions ? true : false}
          />
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row, index) => (
              <TableRow key={index}>
                {columns.map((col, index) => {
                  return (
                    <TableCell
                      style={{ width: col.width }}
                      key={index}
                      component="th"
                      scope="row"
                    >
                      {row[col.field]}
                    </TableCell>
                  )
                })}
                {props.actions ? (
                  <TableCell
                    component="th"
                    scope="row"
                    width={getActionLength(row) * 50 + 'px'}
                  >
                    <Grid container style={{ float: 'left' }}>
                      {props.actions.map((actionFunction, index) => {
                        let action = actionFunction
                        if (typeof actionFunction === 'function')
                          action = actionFunction(row)
                        if (action.icon(row)) {
                          return (
                            <Grid
                              key={index}
                              item
                              xs={12 / getActionLength(row)}
                            >
                              {action.disabled ? (
                                <IconButton
                                  disabled={action.disabled}
                                  onClick={e => {}}
                                >
                                  {action.icon(row)}
                                </IconButton>
                              ) : (
                                <Tooltip
                                  title={
                                    typeof action.tooltip === 'function'
                                      ? action.tooltip(row)
                                      : action.tooltip
                                      ? action.tooltip
                                      : ''
                                  }
                                >
                                  <IconButton
                                    onClick={e => {
                                      action.onClick(e, row)
                                    }}
                                  >
                                    {action.icon(row)}
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Grid>
                          )
                        } else return undefined
                      })}
                    </Grid>
                  </TableCell>
                ) : (
                  undefined
                )}
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        {!(props.hideFooter || (props.minSize && length <= 5)) ? (
          <Table>
            <TableFooter>
              <TableRow>
                <TablePagination
                  style={{ overflow: '', width: '100%' }}
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={3}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        ) : (
          ''
        )}{' '}
      </Paper>
    </TableContainer>
  )
}
