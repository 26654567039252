import React from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { RoleForm } from '../../../components/Forms/RoleForm'
import { getIamService } from '../../../apis/iam'
import { Notifications } from '../../../components/Notification/Notification'
import { Spinner } from '../../../components/Loader/Spinner'

export default function RolesCreate () {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { mutate, isLoading, isError, error } = useMutation(
    values => getIamService().post('/roles', values),
    {
      onMutate: async newRole => {
        await queryClient.cancelQueries('roles')
        const userFormatted = {
          ...newRole
        }

        // Snapshot the previous value
        const previousRoles = queryClient.getQueryData('roles')

        // Optimistically update to the new value
        queryClient.setQueryData('roles', old => {
          if (old) {
            return [...old, userFormatted]
          }
          return [userFormatted]
        })

        // Return a function with the snapshotted value
        return () => queryClient.setQueryData(['roles'], previousRoles)
      }
    }
  )

  function saveRole (roleData) {
    mutate(roleData, {
      onSuccess: () => history.push('/admin/roles'),
      onSettled: () => {
        queryClient.invalidateQueries('roles')
      }
    })
  }

  return (
    <>
      {isLoading ? <Spinner /> : null}
      <Notifications
        isOpenProp={isError}
        message={isError ? error.response?.data?.message || error.message : ''}
        color="danger"
        timeToClose={6000}
        position="tr"
      />
      <RoleForm
        onSubmit={saveRole}
        submitText={isLoading ? 'Saving...' : isError ? 'Error!' : 'Save Role'}
        permissions={[
          { permission: 'Connector.Users.Create' },
          { permission: 'Connector.Roles.Create' },
          { permission: 'Connector.Roles.Edit' },
          { permission: 'Connector.Search.Menu' },
          { permission: 'Connector.Search.SuppliersByCriteria' },
          { permission: 'Connector.Search.ConnectedSuppliers' },
          { permission: 'Connector.Search.ByBvD' },
          { permission: 'Connector.AssignBvd' },
          { permission: 'Connector.Users.Edit' },
          { permission: 'Connector.Tenants.Create' },
          { permission: 'Connector.Tenants.Edit' },
          { permission: 'Connector.Tenants.List' },
          { permission: 'Connector.Mappings.Orbis' },
          { permission: 'Connector.Mappings.Catalyst' },
          { permission: 'Connector.Mappings.Risk' },
          { permission: 'Connector.Mappings.CustomForm' },
          { permission: 'Connector.Configurations.Menu' },
          { permission: 'Connector.Configurations.TenantConfiguration.Edit' },
          { permission: 'Connector.Configurations.TenantConfiguration.List' },
          { permission: 'Connector.Configurations.Applications' },
          { permission: 'Connector.Users.List' },
          { permission: 'Connector.Roles.List' },
          { permission: 'Connector.ImportSuppliers' },
          { permission: 'Connector.CatalystAssesments' },
          { permission: 'Connector.OrbisAssesments' },
          { permission: 'Connector.OrbisTab' }
        ]}
      />
    </>
  )
}
