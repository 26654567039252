import loadingGif from '../../assets/img/ajax-loader.gif'

const styleloader = {
  zIndex: '1000',
  top: ' 50%',
  left: '50%',
  position: 'absolute',
  transform: 'translate(-50%, -50%)'
}

const styleContainer = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  zIndex: '1300',
  backgroundColor: '#f8f8f8ad'
}
export const Spinner = () => {
  return (
    <div style={styleContainer}>
      <div style={styleloader} role="status">
        <img src={loadingGif} alt="loading" />
      </div>
    </div>
  )
}
