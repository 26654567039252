import React from 'react'
import errorTenant from '../../assets/jss/views/ErrorTenant'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(errorTenant)
export default function ErrorTenant () {
  const classes = useStyles()
  return (
    <div className={classes.center}>
      <div className={classes.notFound}>
        <h1 className={classes.h1}>404</h1>
      </div>
      <h2 className={classes.h2}>Oops! Tenant was not found.</h2>
      <p className={classes.p}>
        The tenant you are looking for might have been removed, had its name
        changed or is temporarily unavailable.
      </p>
    </div>
  )
}
