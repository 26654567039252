/*eslint-disable*/
import React, { useEffect, useRef } from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CustomModal from '../../components/CustomModal/CustomModal'
import Select from 'react-select'
import { Typography, IconButton, Grid, Button } from '@material-ui/core'

// material ui icons

import DeleteIcon from '@material-ui/icons/Delete'
// core components

import Card from '../../components/Card/Card.js'
import CardHeader from '../../components/Card/CardHeader.js'

import CardBody from '../../components/Card/CardBody.js'
import {
  cloneObject,
  compareHierarchyNumbers,
  getSuppliers
} from '../../utils/functions'

import DragAndDrop from '../DragDropFile/DragDropFile'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../assets/jss/layout/Admin'
import { env } from '../../utils/Constants'

// Api Calls
import apiApps from '../../services/apiApps'
import apiAppsProd from '../../services/apiAppsProd'
const getBatchStatus = (env === 'local') ? apiApps.getBatchStatus : apiAppsProd.getBatchStatus
const setTenantMappings = (env === 'local') ? apiApps.setTenantMappings : apiAppsProd.setTenantMappings
const uploadBatch = (env === 'local') ? apiApps.uploadBatch : apiAppsProd.uploadBatch

import CustomTreeTable from '../../components/CustomTreeTable/CustomTreeTable'
import { Spinner } from '../../components/Loader/Spinner'

const useStyles = makeStyles(styles)
export default function MappingView (props) {
  const isMounted = useRef(false)
  const classes = useStyles()

  const [isLoading, setIsLoading] = React.useState(true)
  const [importSuppliers, setImportSuppliers] = React.useState([])
  const [fileLoaded, setFileLoaded] = React.useState(false)
  const [showStatus, setShowStatus] = React.useState(false)

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      getBatchStatus(show => {
        setShowStatus(show)
        setIsLoading(false)
      })
    }
    return () => {}
  })

  return (
    <div>
      <Grid container>
        {isLoading ? <Spinner /> : ''}
        <h3 className={classes.h3}>Import Ariba Suppliers</h3>
        <div style={{ width: '95%', margin: 'auto' }} id="testId">
          <Card style={{ minHeight: '300px', paddingBottom: '50px' }}>
            <div style={{ width: '95%', margin: 'auto' }}>
              {showStatus ? (
                <DragAndDrop
                  title="Supplier File"
                  handleFile={file => {
                    setFileLoaded(true)
                    getSuppliers(
                      file,
                      ['smVendorId', 'BvDId'],
                      'BvDId',
                      'Missing',
                      suppliers => {
                        setImportSuppliers(suppliers)
                      }
                    )
                  }}
                />
              ) : isLoading ? (
                ''
              ) : (
                <div>
                  <h2>Supplier Batch Upload in Progress</h2>
                </div>
              )}
              <Grid>
                {fileLoaded ? (
                  <div>
                    <div>
                      <Button
                        disabled={
                          !importSuppliers ||
                          (importSuppliers && importSuppliers.length === 0)
                        }
                        onClick={async () => {
                          setIsLoading(true)
                          const upload = await uploadBatch(importSuppliers)
                          if (upload && upload.success) {
                            setShowStatus(true)
                            props.setValidationMessage(
                              'Suppliers File Uploaded'
                            )
                            props.setSubmitSuccess(true)
                          } else {
                            let message = upload.message
                              ? upload.message
                              : 'Something went wrong, try again'
                            props.setValidationMessage(message)
                            props.setSubmitError(true)
                          }
                          setIsLoading(false)
                        }}
                        className={classes.defaultButton}
                        style={{ minWidth: '250px' }}
                      >{`Upload ${importSuppliers.length} Suppliers`}</Button>
                    </div>
                    {!importSuppliers ||
                    (importSuppliers && importSuppliers.length === 0) ? (
                      <h3>No Suppliers found</h3>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
              </Grid>
            </div>
          </Card>
        </div>
      </Grid>
    </div>
  )
}
