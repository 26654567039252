import React, { useEffect, useRef } from 'react'

// Core
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import loadingGif from '../../assets/img/ajax-loader.gif'

// Style
import { makeStyles } from '@material-ui/core/styles'
import suppliersStyle from '../../assets/jss/views/Suppliers'

// Icon
import searchIcon from '../../assets/img/icons/searchIcon.png'
import { Button } from '@material-ui/core'
import { env } from '../../utils/Constants'

// Api Calls
import apiApps from '../../services/apiApps'
import apiAppsProd from '../../services/apiAppsProd'
const getOrbisContext = (env === 'local') ? apiApps.getOrbisContext : apiAppsProd.getOrbisContext

const useStyles = makeStyles(suppliersStyle)

export default function Suppliers (props) {
  const [bvdId, setBvdId] = React.useState(props.bvdId)
  const [bvdIdField, setBvdIdField] = React.useState(props.bvdIdField)
  const [refresh, setRefresh] = React.useState(false)
  const [context, setContext] = React.useState(props.context)
  const isMounted = useRef(false)

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      getOrbisContext(context => {
        setContext(context)
        props.setContext(context)
      })
    } else {
    }
    return () => {}
  }, [setContext, props])
  const classes = useStyles()
  let url = `https://orbis4.bvdinfo.com/orbis/Companies/report?BvDID=${bvdId}&context=${context}`

  return (
    <div>
      <h3 className={classes.h3}>Search Suppliers by BvD ID</h3>
      <Grid container justifycontent="flex-start" spacing={3}>
        <Grid item md={12}>
          <Card className={classes.card}>
            <CardContent style={{ padding: '15px' }}>
              <div>
                <TextField
                  value={bvdIdField}
                  onChange={e => {
                    props.setBvdIdField(e.target.value)
                    setBvdIdField(e.target.value)
                  }}
                  placeholder="Find a supplier"
                  className={classes.textField}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          alt="search"
                          src={searchIcon}
                          width="24px"
                          height="24px"
                        />
                      </InputAdornment>
                    )
                  }}
                />
                <Button
                  className={classes.defaultButton}
                  onClick={() => {
                    props.setBvdId(bvdIdField)
                    setBvdId(bvdIdField)
                    setRefresh(true)
                    setTimeout(() => {
                      setRefresh(false)
                    }, 350)
                  }}
                >
                  Search
                </Button>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          {bvdId &&
          bvdId.length > 0 &&
          !refresh &&
          context &&
          context.length > 0 ? (
            <div>
              <iframe
                id="iframeOrbis"
                title="Orbis"
                width="100%"
                height="800px"
                src={url}
                frameBorder="0"
                style={{
                  backgroundImage: 'url(' + loadingGif + ')',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat'
                }}
                allowFullScreen={true}
              ></iframe>
              {/* )} */}
            </div>
          ) : bvdId && bvdId.length > 0 ? (
            <iframe
              id="iframeOrbis"
              title="Orbis"
              width="100%"
              height="800px"
              frameBorder="0"
              style={{
                backgroundImage: 'url(' + loadingGif + ')',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
              }}
              allowFullScreen={true}
            ></iframe>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </div>
  )
}
