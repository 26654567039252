import { primaryColor, primaryColorHover } from './Colors'

const defaultStyle = {
  card: {
    boxShadow: 'none',
    border: 'solid 1px #d6dde4',
    backgroundColor: '#fff',
    borderRadius: '4px',
    width: '250px',
    height: '220px'
  },
  link: {
    color: primaryColor,
    textDecoration: 'none',
    '&:hover': {
      color: primaryColorHover,
      backgroundColor: 'transparent'
    }
  },
  h3: {
    fontSize: '22px',
    fontWeight: 'normal'
  },
  defaultPrimaryButton: {
    marginTop: '2px',
    marginLeft: '2%',

    width: '18%',
    backgroundColor: primaryColor,
    textTransform: 'none',
    color: 'white',
    '&:hover': {
      backgroundColor: primaryColorHover
    },
    '&:disabled': {
      backgroundColor: 'lightGray'
    }
  },
  defaultButton: {
    marginTop: '2px',
    marginLeft: '2%',
    fontWeight: 'bold',
    borderColor: primaryColor,
    border: '1.5px',
    borderStyle: 'solid',
    width: '18%',
    backgroundColor: 'white',
    textTransform: 'none',
    color: primaryColor,
    '&:hover': {
      backgroundColor: '#ebeeff'
    },
    '&:disabled': {
      backgroundColor: 'lightGray'
    }
  },
  textField: {
    width: '80%',
    height: '40px',
    backgroundColor: 'rgb(250, 251, 252)'
  },
  selectField: {
    width: '80%',
    height: '50px'
  }
}

export default defaultStyle
