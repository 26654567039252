import defaultStyle from '../Default'

const tenantsStyle = {
  ...defaultStyle,
  card: {
    ...defaultStyle.card,
    width: '100%',
    height: '100%'
  },
  defaultButton: {
    ...defaultStyle.defaultButton,
    float: 'right',
    width: '10%',
    marginTop: '10px',
    marginLeft: '0',
    marginBottom: '10px'
  },
  textField: {
    ...defaultStyle.textField,
    width: '90%',
    margin: '20px 10px 20px 10px',
  },
}

export default tenantsStyle
