import React from 'react'

// @material-ui/core components
//import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button } from '@material-ui/core'

import Dialog from '@material-ui/core/Dialog'

//import styles from '../../assets/jss/material-dashboard-pro-react/views/Apps/customModalStyles.js'
//const useStyles = makeStyles(styles)
export default function CustomModal (props) {
  // const classes = useStyles()
  return (
    <Dialog {...props.dialogProps} open={true} style={props.style}>
      <div style={{ alignItems: 'center' }}>
        <Grid container style={{ overflow: 'hidden' }}>
          <div style={{ textAlign: 'center', width: '100%' }}>
            <h1>{props.title}</h1>
          </div>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {props.children}
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              paddingLeft: '5%',
              paddingRight: '5%',
              display: 'flex',
              textAlign: 'center'
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                {props.message}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  className={props.confirmBtnCssClass}
                  onClick={props.onConfirm}
                  style={{ width: '90%' }}
                >
                  Confirm
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  className={props.cancelBtnCssClass}
                  onClick={props.onCancel}
                  style={{ width: '90%' }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}
