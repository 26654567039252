import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'
import TableRow from '@material-ui/core/TableRow'
import CustomReactTable from '../CustomReactTable/CustomReactTable'

import { Close, Minimize } from '@material-ui/icons'
import Add from '@material-ui/icons/Add'

export default function CustomTreeTable (props) {
  const [data, setData] = useState([])

  const isMounted = useRef(false)
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true

      //  window.addEventListener('resize', listenerWidth)
    } else {
    }
    let transformedData = []

    if (props.data && props.data.length > 0) {
      for (let index = 0; index < props.data.length; index++) {
        const element = props.data[index]

        transformedData.push(element)
        let label = ''
        if (typeof props.mainNode === 'string') {
          label = element[props.mainNode]
        } else if (Array.isArray(props.mainNode)) {
          props.mainNode.forEach(node => {
            label += ' ' + element[node]
          })
          label = label.trim()
        }
        let row = (
          <TreeView
            defaultCollapseIcon={<Minimize />}
            defaultExpandIcon={<Add />}
            defaultEndIcon={<Close />}
          >
            <TreeItem nodeId="1" label={label ? label : ''}>
              <Table>
                <TableBody>
                  {props.columns.map((id, key) => {
                    return (
                      <TableRow key={key}>
                        <TableCell component="th" scope="row">
                          {id.title}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <div style={{ display: 'inline-block' }}>
                            {element[id.field]}
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TreeItem>
          </TreeView>
        )
        transformedData[index].main = row
        //transformedData.push({ main: row })
      }
    }
    // return transformedData
    setData(transformedData)
    // this function will be ran when the component is re-renderd or unmounted
    return () => {}
    //you need to add in this array the properties that need to be checked for changes and the ones you will be using in this function
    //, if one property changes, the useEffect function will be ran again (once per DOM change)
    // you can send all the props and if something updated this function will be ran
  }, [props.data.length, props.data, props.mainNode, props.columns])

  const width = useSelector(state => state.main.clientWidth)

  const treeTableWidth = props.treeTableWidth ? props.treeTableWidth : -1
  return (
    <div>
      <CustomReactTable
        rightButton={props.rightButton}
        searchPlaceHolder={props.searchPlaceHolder}
        minSize={props.minSize}
        defaultOrderBy={props.defaultOrderBy}
        tableTitle={props.tableTitle}
        noInitialOrder={props.noInitialOrder}
        headerBackgroundColor={props.headerBackgroundColor}
        headerColor={props.headerColor}
        hideSearch={props.hideSearch}
        onRowClick={e => {}}
        defaultOrder={props.defaultOrder}
        icons={props.icons}
        columns={
          treeTableWidth > width
            ? [{ title: '', field: 'main' }]
            : props.columns
        }
        data={data}
        options={props.options}
        actions={props.actions}
      />
    </div>
  )
}
