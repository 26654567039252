import React, { useState } from 'react'
import CustomTreeTable from '../../components/CustomTreeTable/CustomTreeTable'

import { useHistory } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'

// Providers
import { useAuth } from '../../Providers/Auth'

// Core
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

// Style
import { makeStyles } from '@material-ui/core/styles'
import userStyle from '../../assets/jss/views/User'

// Icons
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Edit from '@material-ui/icons/Edit'
import { getIamService } from '../../apis/iam'
import { Spinner } from '../../components/Loader/Spinner'
import { Notifications } from '../../components/Notification/Notification'
import DeleteIcon from '@material-ui/icons/Delete'
import RefreshIcon from '@material-ui/icons/Refresh'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Dialog from '@material-ui/core/Dialog'
import { DialogTitle, IconButton, TextField } from '@material-ui/core'
const useStyles = makeStyles(userStyle)

export default function Users (props) {
  const [modifyLoading, setModifyLoading] = useState(false)
  const [showPassDialog, setShowPassDialog] = useState(false)
  const [showPass, setShowPass] = useState(false)

  const [newPass, setNewPass] = useState(undefined)
  const [currentRow, setCurrentRow] = useState(undefined)

  const [lower, setLower] = useState(false)
  const [upper, setUpper] = useState(false)
  const [special, setSpecial] = useState(false)
  const [number, setNumber] = useState(false)
  const [length, setLength] = useState(false)

  const classes = useStyles()
  const queryClient = useQueryClient()
  const { roles, tenant } = useAuth()
  const columns = [
    { title: 'Id', field: 'userId' },
    { title: 'Name', field: 'firstName' },
    { title: 'Email', field: 'email' },
    { title: 'Tenant', field: 'tenantId' },
    { title: 'Role', field: 'role' },
    { title: 'Status', field: 'enabled' }
  ]
  const history = useHistory()

  const { data, isLoading, error, isError, isFetching, refetch } = useQuery(
    'users',
    () =>
      getIamService()
        .get(
          roles
            ? roles === 'superAdmin'
              ? '/users'
              : `/tenants/${tenant}/users`
            : ''
        )
        .then(result => {
          return result.data.map(user => {
            const newData = {
              ...user,
              role: user.roles[0],
              enabled: user.enabled ? 'Enabled' : 'Disabled',
              enabledBool: user.enabled,
              password: ''
            }
            delete newData['roles']
            queryClient.setQueryData(
              ['users', newData.tenantId, newData.userId],
              newData
            )
            return newData
          })
        })
  )

  function newUser () {
    history.push('/admin/users/create')
  }

  return (
    <>
      {showPassDialog ? (
        <Dialog
          open={showPassDialog}
          fullWidth
          maxWidth={'md'}
          style={{ overflow: 'hidden', maxWidth: '' }}
        >
          <DialogTitle>
            <IconButton
              onClick={() => {
                setNewPass(undefined)
                setLength(false)
                setNumber(false)
                setSpecial(false)
                setUpper(false)
                setLower(false)
                setShowPassDialog(false)
                setCurrentRow(undefined)
              }}
              style={{
                float: 'right',
                height: '30px',
                width: '30px'
              }}
            >
              X
            </IconButton>
          </DialogTitle>
          <div>
            <h2 style={{ textAlign: 'center' }}>Set temporary password</h2>
          </div>

          <div style={{ overflow: 'hidden' }}>
            <Grid container style={{ padding: '20px', paddingTop: '0px' }}>
              <Grid item xs={12}>
                <form
                  autoComplete="off"
                  style={{ width: '99%', margin: 'auto' }}
                >
                  <div style={{ margin: 'auto' }}>
                    <TextField
                      type={showPass ? 'text' : 'password'}
                      style={{ margin: 'auto', width: '100%' }}
                      variant="outlined"
                      value={newPass}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => {
                              setShowPass(!showPass)
                            }}
                          >
                            {showPass ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        )
                      }}
                      onChange={e => {
                        setNewPass(e.target.value)

                        if (e.target.value.toUpperCase() !== e.target.value) {
                          setLower(true)
                        } else setLower(false)
                        if (
                          e.target.value.toLocaleLowerCase() !== e.target.value
                        ) {
                          setUpper(true)
                        } else setUpper(false)
                        const specialChars = `/[!@#$%^&*()_+-=[]{};':"\\|,.<>/?]+/;`

                        const isSpecialCharsPresent = specialChars
                          .split('')
                          .some(char => e.target.value.includes(char)) // true if present and false if not

                        if (isSpecialCharsPresent) {
                          setSpecial(true)
                        } else setSpecial(false)
                        const numbers = `1234567890`

                        const containsNumbers = numbers
                          .split('')
                          .some(char => e.target.value.includes(char)) // true if present and false if not
                        if (containsNumbers) {
                          setNumber(true)
                        } else setNumber(false)
                        if (e.target.value.length >= 8) {
                          setLength(true)
                        } else setLength(false)
                      }}
                    ></TextField>
                  </div>
                </form>
              </Grid>
              <Grid item xs={12}>
                <div style={{ margin: 'auto', paddingTop: '20px' }}>
                  <div
                    style={{
                      margin: 'auto',
                      width: '99%',
                      paddingTop: '20px',
                      fontWeight: '1px'
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        {lower ? '✓' : 'X'} Password must contain a lower case
                        letter
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {upper ? '✓' : 'X'} Password must contain an upper case
                        letter
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {special ? '✓' : 'X'} Password must contain a special
                        character{' '}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {number ? '✓' : 'X'} Password must contain a number
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {length ? '✓' : 'X'} Password must contain at least 8
                        characters
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <Button
                  disabled={!upper || !lower || !special || !number || !length}
                  onClick={() => {
                    setShowPassDialog(false)
                    setModifyLoading(true)
                    getIamService()
                      .put('users/reset', {
                        tenantId: currentRow.tenantId,
                        userId: currentRow.userId,
                        password: newPass
                      })
                      .then(result => {
                        setNewPass(undefined)
                        setLength(false)
                        setNumber(false)
                        setSpecial(false)
                        setUpper(false)
                        setLower(false)
                        setCurrentRow(undefined)
                        //  console.log(result)
                        refetch()
                        setModifyLoading(false)
                        props.setValidationMessage(result.data.message)
                        props.setSubmitSuccess(true)
                      })
                      .catch(result => {
                        setNewPass(undefined)
                        setLength(false)
                        setNumber(false)
                        setSpecial(false)
                        setUpper(false)
                        setLower(false)
                        setCurrentRow(undefined)
                        setModifyLoading(false)
                        props.setValidationMessage(result.response.data.message)
                        props.setSubmitError(true)
                      })
                  }}
                  className={classes.defaultButton}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </div>
        </Dialog>
      ) : (
        ''
      )}
      {isLoading || modifyLoading ? <Spinner /> : null}
      <h3 className={classes.h3}>Users{isFetching ? '...' : null}</h3>
      <Notifications
        isOpenProp={isError}
        message={isError ? error.response?.data?.message || error.message : ''}
        color="danger"
        timeToClose={6000}
        position="tr"
      />

      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <CustomTreeTable
            rightButton={
              <Button
                style={{ width: '100px', float: 'right' }}
                className={classes.defaultPrimaryButton}
                onClick={() => newUser()}
              >
                + Add User
              </Button>
            }
            mainNode={['firstName']}
            treeTableWidth={1300}
            columns={columns}
            data={data ? data : []}
            options={{
              actionsColumnIndex: -1,
              sorting: true,
              showTitle: false
            }}
            actions={[
              {
                icon: () => <Edit />,
                tooltip: 'Edit User',
                onClick: (event, rowData) => {
                  history.push(
                    `/admin/users/edit/${rowData.userId}/${rowData.tenantId}`
                  )
                }
              },
              {
                icon: rowData => {
                  return rowData && rowData.enabledBool ? (
                    <BlockIcon />
                  ) : (
                    <CheckCircleOutlineIcon />
                  )
                },
                tooltip: rowData => {
                  return rowData && rowData.enabledBool
                    ? 'Disable User'
                    : 'Enable User'
                },
                onClick: (event, rowData) => {
                  setModifyLoading(true)
                  if (rowData.enabledBool) {
                    getIamService()
                      .put('users/disable', {
                        tenantId: rowData.tenantId,
                        userId: rowData.userId
                      })
                      .then(result => {
                        refetch()
                        setModifyLoading(false)
                        props.setValidationMessage(result.data.message)
                        props.setSubmitSuccess(true)
                      })
                      .catch(result => {
                        setModifyLoading(false)
                        props.setValidationMessage(result.response.data.message)
                        props.setSubmitError(true)
                      })
                  } else {
                    getIamService()
                      .put('users/enable', {
                        tenantId: rowData.tenantId,
                        userId: rowData.userId
                      })
                      .then(result => {
                        refetch()
                        setModifyLoading(false)
                        props.setValidationMessage(result.data.message)
                        props.setSubmitSuccess(true)
                      })
                      .catch(result => {
                        setModifyLoading(false)
                        props.setValidationMessage(result.response.data.message)
                        props.setSubmitError(true)
                      })
                  }
                  //console.log(rowData)
                }
              },
              {
                icon: () => <DeleteIcon />,
                tooltip: 'Delete User',
                onClick: (event, rowData) => {
                  setModifyLoading(true)
                  getIamService()
                    .delete('users', {
                      data: {
                        tenantId: rowData.tenantId,
                        userId: rowData.userId
                      }
                    })
                    .then(result => {
                      refetch()
                      setModifyLoading(false)
                      props.setValidationMessage(result.data.message)
                      props.setSubmitSuccess(true)
                    })
                    .catch(result => {
                      setModifyLoading(false)
                      props.setValidationMessage(result.response.data.message)
                      props.setSubmitError(true)
                    })
                }
              },
              {
                icon: () => <RefreshIcon />,
                tooltip: 'Set Temporary Password',
                onClick: (event, rowData) => {
                  setCurrentRow(rowData)
                  setShowPassDialog(true)
                  setNewPass(undefined)
                  setLength(false)
                  setNumber(false)
                  setSpecial(false)
                  setUpper(false)
                  setLower(false)
                }
              }
            ]}
          />
        </Grid>
      </Grid>
    </>
  )
}
