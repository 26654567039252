import defaultStyle from '../Default'

const tenantsEditStyle = {
  ...defaultStyle,
  card: {
    ...defaultStyle.card,
    width: '100%',
    height: '100%'
  },
  defaultButton: {
    ...defaultStyle.defaultButton,
    width: '12%',
    marginLeft: '0',
    marginTop: '20px'
  },
  textField: {
    ...defaultStyle.textField,
    width: '90%',
    margin: '20px 10px 20px 10px',
  },
  selectField: {
    ...defaultStyle.selectField,
    width: '90%',
    margin: '20px 10px 20px 10px',
  }
}

export default tenantsEditStyle
