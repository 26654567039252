import { useQuery, useQueryClient } from 'react-query'
import { getIamService } from '../apis/iam'
export function useGetRoles () {
  const queryClient = useQueryClient()

  return useQuery('roles', () =>
    getIamService()
      .get('/roles')
      .then(result => {
        return result.data.map(role => {
          const newData = {
            ...role
          }
          queryClient.setQueryData(['roles', newData.roleId], newData)
          return newData
        })
      })
  )
}
