import { useHistory, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { TenantForm } from '../../../components/Forms/TenantForm'
import { Notifications } from '../../../components/Notification/Notification'
import { Spinner } from '../../../components/Loader/Spinner'
import { useGetTenantById, useUpdateTenant } from '../../../hooks/useTenants'

export default function TenantsEdit() {
  const history = useHistory()
  const { id } = useParams()

  const queryClient = useQueryClient()
  const getTenant = useGetTenantById(id)

  const updatedTenant = useUpdateTenant(id)
  /*
  if (getTenant.isLoading) {
    return <Spinner />
  }
*/
  function saveTenant(tenantData) {
    updatedTenant.mutate(tenantData, {
      onSuccess: () => history.push('/admin/tenants'),
      onSettled: () => {
        queryClient.invalidateQueries('tenants')
        queryClient.invalidateQueries(['tenants', tenantData.tenantId])
      }
    })
  }

  return (
    <>
      {updatedTenant.isLoading || getTenant.isLoading ? <Spinner /> : null}
      <Notifications
        isOpenProp={getTenant.isError || updatedTenant.isError}
        message={
          updatedTenant.isError
            ? updatedTenant.error.response?.data?.message ||
              updatedTenant.error.message
            : getTenant.isError
            ? getTenant.error.response?.data?.message || getTenant.error.message
            : ''
        }
        color="danger"
        timeToClose={6000}
        position="tr"
      />
      <TenantForm
        initialValues={getTenant.data}
        onSubmit={saveTenant}
        submitText={
          updatedTenant.isLoading
            ? 'Saving...'
            : updatedTenant.isError
            ? 'Error!'
            : 'Save Tenant'
        }
        isEdit={true}
      />
    </>
  )
}
