import { getTenant } from '../Providers/Auth'
import { getWidth } from '../utils/functions'

export const initialState = {
  clientWidth: getWidth(),
  selectedApp: sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`))
    : localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(localStorage.getItem(`BvD.SelectedApp.${getTenant()}`))
    : undefined,
  appList: localStorage.getItem(`BvD.AppList.${getTenant()}`)
    ? JSON.parse(localStorage.getItem(`BvD.AppList.${getTenant()}`))
    : [],
  refresh: false
}

export const ACTION_CLIENTWIDTH = 'MAIN/CLIENTWIDTH'
export const ACTION_SELECTED_APP = 'MAIN/SELECTED_APP'
export const ACTION_APPLIST = 'MAIN/ACTION_APPLIST'
export const ACTION_REFRESH = 'MAIN/ACTION_REFRESH'

export const setClientWidth = value => ({
  type: ACTION_CLIENTWIDTH,
  payload: value
})
export const setSelectedApp = value => ({
  type: ACTION_SELECTED_APP,
  payload: value
})
export const setRefresh = value => ({
  type: ACTION_REFRESH,
  payload: value
})
export const setAppList = value => ({
  type: ACTION_APPLIST,
  payload: value
})
export default function mainReducer (state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_CLIENTWIDTH:
      return {
        ...state,
        clientWidth: payload
      }
    case ACTION_SELECTED_APP:
      localStorage.setItem(
        `BvD.SelectedApp.${getTenant()}`,
        JSON.stringify(payload)
      )
      sessionStorage.setItem(
        `BvD.SelectedApp.${getTenant()}`,
        JSON.stringify(payload)
      )
      return {
        ...state,
        selectedApp: payload
      }
    case ACTION_APPLIST:
      localStorage.setItem(
        `BvD.AppList.${getTenant()}`,
        JSON.stringify(payload)
      )
      return {
        ...state,
        appList: payload
      }
    case ACTION_REFRESH:
      return {
        ...state,
        refresh: payload
      }

    default:
      return state
  }
}
