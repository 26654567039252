import { useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { TenantForm } from '../../../components/Forms/TenantForm'
import { Spinner } from '../../../components/Loader/Spinner'
import { useGetTenantById, useUpdateTenant } from '../../../hooks/useTenants'
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
// Providers
import { useAuth } from '../../../Providers/Auth'

export default function TenantConfiguration (props) {
  const history = useHistory()
  const { tenant } = useAuth()

  const queryClient = useQueryClient()
  const getTenant = useGetTenantById(tenant)

  let updatedTenant = useUpdateTenant(tenant)
  function handleClose () {
    updatedTenant.reset()
  }
  function saveTenant (tenantData) {
    updatedTenant.mutate(tenantData, {
      onSuccess: () => history.push('/admin/tenant/configuration'),
      onSettled: () => {
        queryClient.invalidateQueries('tenants')
        queryClient.invalidateQueries(['tenants', tenantData.tenantId])
      }
    })
  }

  return (
    <>
      {updatedTenant.isLoading || getTenant.isLoading ? <Spinner /> : null}

      <Snackbar
        open={
          !updatedTenant.isLoading
            ? updatedTenant.data && !updatedTenant.isError
              ? true
              : false
            : false
        }
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {'Tenant Updated Successfully'}
        </Alert>
      </Snackbar>
      <Snackbar
        open={getTenant.isError || updatedTenant.isError}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {updatedTenant.isError
            ? updatedTenant.error.response?.data?.message ||
              updatedTenant.error.message
            : getTenant.isError
            ? getTenant.error.response?.data?.message || getTenant.error.message
            : ''}
        </Alert>
      </Snackbar>

      <TenantForm
        title={'My Account'}
        initialValues={getTenant.data}
        onSubmit={saveTenant}
        submitText={
          updatedTenant.isLoading
            ? 'Saving...'
            : updatedTenant.isError
            ? 'Error!'
            : 'Save Tenant'
        }
        isEdit={true}
      />
    </>
  )
}
