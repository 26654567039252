import axios from 'axios'
import { signOut } from '../layout/Admin/Admin'
import { v4 as uuidv4 } from 'uuid'
import { ApiAdmin } from '../utils/Constants'
import { ApiAdminProd } from '../utils/Constants'
import { env } from '../utils/Constants'
const endPoint = env === 'local' ? ApiAdmin.EndPoint : ApiAdminProd.EndPoint

function sleep (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export default async function customAxios (request, requestGUID) {
  let count = 0
  const maxTries = 3
  if (!requestGUID) requestGUID = uuidv4()
  request.headers.requestguid = requestGUID

  while (maxTries > count) {
    try {
      return await axios(request)
    } catch (err) {
      if (err.response.status === 504) {
        const asyncRequest = {
          method: 'GET',
          url: `${endPoint}/asyncRequest/${requestGUID}`,
          headers: {
            ...request.headers
          }
        }
        //console.log(new Date())
        await sleep(5000)
        //console.log(new Date())
        return await customAxios(asyncRequest, requestGUID)
      } else if (err.response.status !== 401) {
        // handle exception
        if (++count === maxTries) {
          throw err
        }
      } else {
        await signOut()
        //document.location.reload()
        return undefined
      }
    }
  }
}
