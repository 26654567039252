import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

// Core

import Button from '@material-ui/core/Button'

// Icon

//Style
import homeStyle from '../../assets/jss/views/Home'
import Card from '../../components/Card/Card'
import Grid from '@material-ui/core/Grid'
// import like this
import OrbisIcon from '../../assets/img/icons/orbisTransparent.png'
let currentWindow = undefined
const useStyles = makeStyles(homeStyle)
function checkUser (openWindow) {
  if (!window.localStorage.getItem('externalLogin'))
    setTimeout(async () => {
      checkUser(openWindow)
    }, 500)
  else {
    if (openWindow) openWindow.close()
    window.location.href = window.location.origin
  }
}

export default function ExternalLogin (props) {
  const classes = useStyles()
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={3}>
        <Card>
          <img
            style={{ width: '50%', margin: 'auto' }}
            src={OrbisIcon}
            alt=""
          />
          <Button
            style={{ width: '90%', margin: 'auto' }}
            className={classes.defaultButton}
            onClick={() => {
              if (currentWindow) currentWindow.close()
              const h = 570
              const w = 520
              var left = document.documentElement.clientWidth / 2 - w / 2
              var top = document.documentElement.clientHeight / 2 - h / 2
              currentWindow = window.open(
                window.location.origin,
                'Login',
                'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
                  w +
                  ', height=' +
                  h +
                  ', top=' +
                  top +
                  ', left=' +
                  left
              )

              checkUser(currentWindow)
            }}
          >
            Login
          </Button>
        </Card>
      </Grid>
    </Grid>
  )
}
