import { primaryColor } from '../Colors'
import defaultStyle from '../Default'

const adminStyle = theme => ({
  ...defaultStyle,
  root: {
    display: 'flex'
  },
  centerV: {
    margin: 0,
    position: 'absolute',
    top: '50%',
    '-ms-transform': 'translateY(-50%)',
    transform: 'translateY(-50%)'
  },
  appBar: {
    height: '63px',
    boxShadow: 'none',
    borderBottom: '1px solid #d6dde4',
    borderTop: '2px solid #d6dde4',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    backgroundColor: 'white'
  },
  actionButton: {
    margin: '0 0 0 5px',
    width: '50%',
    padding: '5px',
    '& svg,& .fab,& .fas,& .far,& .fal,& .material-icons': {
      marginRight: '0px'
    },
    backgroundColor: primaryColor,
    color: 'white'
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: '50px'
  },
  toolbar: {
    height: '55px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1)
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar
  },
  defaultButton: {
    ...defaultStyle.defaultButton,
    float: 'right',
    width: '10%',
    marginTop: '10px',
    marginLeft: '0',
    marginBottom: '10px'
  },
  buttonDanger: {
    ...defaultStyle.defaultButton,
    float: 'right',
    width: '10%',
    marginTop: '10px',
    marginLeft: '0',
    marginBottom: '10px',
    backgroundColor: '#ffcccb',
    '&:hover': {
      backgroundColor: '#ff726f'
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  toolBar: {
    marginLeft: '15px',
    paddingLeft: 0
  },
  linkItem: {
    paddingLeft: '8px',
    borderBottom: 'rgba(213,219,255,.26) 1px solid',
    '&:hover': {
      backgroundColor: '#030f59'
    }
  },
  adminMenuItem: {
    margin: 'auto',
    paddingLeft: '6px',
    borderBottom: 'rgba(213,219,255,.26) 1px solid',
    borderTop: 'rgba(213,219,255,.26) 1px solid',
    '&:hover': {
      backgroundColor: '#efefef'
    }
  },
  menuItem: {
    paddingLeft: '6px',
    borderBottom: 'rgba(213,219,255,.26) 2px solid',
    '&:hover': {
      backgroundColor: '#efefef'
    }
  },
  linkItemActive: {
    borderLeft: '4px solid #e87722',
    backgroundColor: '#030f59',
    paddingLeft: '6px',
    borderBottom: 'rgba(213,219,255,.26) 1px solid'
  },
  card: {
    boxShadow: 'none',
    border: 'solid 1px #d6dde4',
    backgroundColor: '#fff',
    borderRadius: '4px'
  },
  titleBar: {
    color: '#001489',
    marginLeft: '10px',
    fontWeight: 'bold',
    fontSize: '20px',
    flexGrow: 1
  }
})

export default adminStyle
