import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import './index.css'
import App from './App'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { AuthProvider } from './Providers/Auth'
import { Provider } from 'react-redux'
import store from './store'
import ExternalLogin from './views/ExternalLogin/ExternalLogin'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry (failureCount, error) {
        if (error.response?.status === 404) return false
        else if (failureCount < 2) return true
        else return false
      }
    },
    mutations: {
      onError: (err, variables, rollback) =>
        typeof rollback === 'function' ? rollback() : null
    }
  }
})

const hist = createBrowserHistory()
ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route exact path="/e/login" component={ExternalLogin} />
      <Route
        path="/"
        render={() => (
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                <App />
              </AuthProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </Provider>
        )}
      />
    </Switch>
  </Router>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
