import React, { useState, forwardRef, useEffect, useRef } from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import AddIcon from '../../assets/img/icons/chainTransparent.png'
import OrbisIcon from '../../assets/img/icons/orbisTransparent.png'

//import Select from '@material-ui/core/Select'

// Style
import { makeStyles } from '@material-ui/core/styles'
import suppliersCriteriaStyle from '../../assets/jss/views/SuppliersCriteria'
import { env } from '../../utils/Constants'

import ArrowDownward from '@material-ui/icons/ArrowDownward'

import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'

import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
// import LinkIcon from '@material-ui/icons/Link'
import AribaIcon from '../../assets/img/icons/aribaTransparent.png'
import CustomTreeTable from '../../components/CustomTreeTable/CustomTreeTable'

import { Spinner } from '../../components/Loader/Spinner'

// Function
import apiApps from '../../services/apiApps'
import apiAppsProd from '../../services/apiAppsProd'
const getConnectedBvDSuppliers =
  env === 'local'
    ? apiApps.getConnectedBvDSuppliers
    : apiAppsProd.getConnectedBvDSuppliers

const useStyles = makeStyles(suppliersCriteriaStyle)

export default function SearchConnectedSuppliers (props) {
  const isMounted = useRef(false)
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
    } else {
    }
    // this function will be ran when the component is re-renderd or unmounted
    return () => {}
    //you need to add in this array the properties that need to be checked for changes and the ones you will be using in this function
    //, if one property changes, the useEffect function will be ran again (once per DOM change)
    // you can send all the props and if something updated this function will be ran
  })
  const classes = useStyles()
  const [data, setData] = useState(props.data ? props.data : [])
  const [searchInfo, setSearchInfo] = useState(
    props.searchInfo
      ? props.searchInfo
      : {
          companyName: '',
          bvdId: ''
        }
  )
  const [infoIsLoading, setInfoIsLoading] = useState(false)
  const [realm, setRealm] = React.useState(
    props.realm ? props.realm : undefined
  )
  async function getInfoSearch () {
    setInfoIsLoading(true)
    let response
    response = await getConnectedBvDSuppliers(searchInfo)

    if (response && response.success) {
      setData(response.data.data)
      props.setData(response.data.data)

      if (response.data.realm) {
        setRealm(response.data.realm)
        props.setRealm(response.data.realm)
      }
    }
    setInfoIsLoading(false)
  }
  const tableIcons = {
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  }
  const columns = [
    { title: 'BvD ID', field: 'bvdId' },
    { title: 'Supplier Name', field: 'supplierName' },
    { title: 'Ariba Supplier Id', field: 'supplierId' }
  ]

  function onFieldChange (field, value, check = false) {
    if (check) {
      if (value) {
        searchInfo.Exclusions.push(field)
      } else {
        let selected = searchInfo.Exclusions.findIndex(x => x === field)
        if (selected !== -1) {
          searchInfo.Exclusions.splice(selected, 1)
        }
      }
    } else {
      searchInfo[field] = value
    }
    setSearchInfo({ ...searchInfo })
    props.setSearchInfo({ ...searchInfo })
  }
  let actions = [
    rowData => ({
      icon: () => <img style={{ width: '25px' }} src={AribaIcon} alt="" />,
      tooltip: 'Go to Ariba',
      onClick: (event, rowData) => {
        if (realm && rowData.supplierId)
          window.open(
            `https://s1.au.cloud.ariba.com/Sourcing/Main/ad/viewSupplier360Profile/ariba.sourcing.dashboard.SMMainActionsController?realm=${realm}&smVendorId=${rowData.supplierId}`
          )
      }
    }),
    rowData => ({
      icon: () => <img style={{ width: '25px' }} src={AddIcon} alt="" />,
      tooltip: 'Supplier Orbis Enhancement Details',
      onClick: (event, rowData) => {
        if (rowData.supplierId) {
          props.history.push(`/admin/supplier?id=${rowData.supplierId}`)
        }
      }
    }),
    rowData => ({
      icon: () => <img style={{ width: '25px' }} src={OrbisIcon} alt="" />,
      tooltip: 'Open Catalyst',
      onClick: (event, rowData) => {
        if (rowData.supplierId) {
          props.history.push(
            `/admin/catalystAssesments?supplierId=${rowData.supplierId}`
          )
        }
      }
    })
  ]
  if (props.actions) {
    props.actions.forEach(element => {
      actions.push(element)
    })
  }
  return (
    <div>
      {infoIsLoading ? <Spinner /> : ''}
      <h3 className={classes.h3}>
        {props.title ? props.title : 'Search Connected Suppliers'}
      </h3>
      <Grid container justifyContent="center">
        <Grid item md={12}>
          <Card style={{ width: '100%' }} className={classes.card}>
            {props.cardTitle ? props.cardTitle : ''}
            <CardContent>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Company Name"
                        variant="outlined"
                        size="small"
                        className={classes.textField}
                        value={searchInfo.companyName}
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            getInfoSearch()
                          }
                        }}
                        onChange={e => {
                          onFieldChange('companyName', e.target.value)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="BvD Id"
                        variant="outlined"
                        size="small"
                        className={classes.textField}
                        value={searchInfo.bvdId}
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            getInfoSearch()
                          }
                        }}
                        onChange={e => {
                          onFieldChange('bvdId', e.target.value)
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <Divider variant="middle" />
                  <Button
                    disabled={!searchInfo.companyName && !searchInfo.bvdId}
                    className={classes.defaultButton}
                    onClick={() => getInfoSearch()}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <div>
            <h4 style={{ margin: '50px 0 20px 0' }}>Your search results:</h4>
            <CustomTreeTable
              defaultOrder={'desc'}
              icons={tableIcons}
              columns={columns}
              data={data}
              actions={actions}
              options={{
                actionsColumnIndex: -1,
                sorting: true,
                showTitle: false
              }}
              mainNode={['bvdId', 'supplierName']}
              treeTableWidth={900}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
