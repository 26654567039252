import customAxios from './customAxios'
import { ApiAdminProd as ApiAdmin } from '../utils/Constants'
import { Auth } from 'aws-amplify'
import { getTenant } from '../Providers/Auth'
import { signOut } from '../layout/Admin/Admin'

/*
async function getSupplierData (dataValues) {
  if (!dataValues.MatchPercent || !isNaN(Number(dataValues.MatchPercent))) {
    dataValues.MatchPercent = '85'
  }
  let data = { ...dataValues }
  if (data.Country && data.Country.value) {
    data.Country = data.Country.value
  }

  var config = {
    method: 'POST',
    url: `${ApiAdmin.EndPoint}/match`,
    // url: 'https://hrnxpjb9l9.execute-api.us-west-2.amazonaws.com/Stage/match',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  }

  let response = await customAxios(config)

  if (response) {
    return { success: true, data: response.data }
  } else {
    return {
      success: false,
      message: 'Something went wrong, try again'
    }
  }
}*/

const getAccessToken = async function () {
  const authConfigLS = localStorage.getItem(
    `CognitoIdentity.Configure.${getTenant()}`
  )
  if (!authConfigLS) {
    await signOut()
    //document.location.reload()
  }
  const authConfig = JSON.parse(authConfigLS)

  if (!authConfig) {
    await signOut()
    // document.location.reload()
  } else {
    const userEmail = localStorage.getItem(
      `CognitoIdentityServiceProvider.${authConfig.Auth.userPoolWebClientId}.LastAuthUser`
    )
    return (
      'Bearer ' +
      localStorage.getItem(
        `CognitoIdentityServiceProvider.${authConfig.Auth.userPoolWebClientId}.${userEmail}.accessToken`
      )
    )
  }
}

async function getSupplierData (dataValues) {
  let data = { ...dataValues }
  if (data.Country && data.Country.value) {
    data.Country = data.Country.value
  }
  const appId = sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : undefined

  var config = {
    method: 'POST',
    url: `${ApiAdmin.EndPoint}/apps/${appId}/suppliers/match`,
    // url: 'https://hrnxpjb9l9.execute-api.us-west-2.amazonaws.com/Stage/match',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken()
    },
    data: data
  }

  let response = await customAxios(config)

  if (response) {
    return { success: true, data: response.data }
  } else {
    return {
      success: false,
      message: 'Something went wrong, try again'
    }
  }
}
async function validateConnectivity (currentObject, modelId) {
  const appId = sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : undefined
  var post = {
    method: 'POST',
    url: `${ApiAdmin.EndPoint}/apps/${appId}/orbis/connectivity`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken()
    },
    data: { ...currentObject }
  }

  let response = await customAxios(post)

  if (response) {
    return { success: true, data: response.data }
  } else {
    return {
      success: false,
      message: 'Something went wrong, try again'
    }
  }
}

async function startCatalystAssesment (bvdId, modelId) {
  const appId = sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : undefined
  var post = {
    method: 'POST',
    url: `${ApiAdmin.EndPoint}/apps/${appId}/catalyst/assesment`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken()
    },
    data: { bvdId: bvdId, modelId: modelId }
  }

  let response = await customAxios(post)

  if (response) {
    return { success: true, data: response.data }
  } else {
    return {
      success: false,
      message: 'Something went wrong, try again'
    }
  }
}

async function setTenantMappings (mappings) {
  const appId = sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : undefined
  var put = {
    method: 'PUT',
    url: `${ApiAdmin.EndPoint}/apps/${appId}/mapping`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken()
    },
    data: { ...mappings }
  }

  let response = await customAxios(put)

  if (response) {
    return { success: true, data: response.data }
  } else {
    return {
      success: false,
      message: 'Something went wrong, try again'
    }
  }
}

async function setSupplierBvdId (supplierId, bvdId) {
  const appId = sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : undefined
  var put = {
    method: 'PUT',
    url: `${ApiAdmin.EndPoint}/apps/${appId}/supplier/${supplierId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken()
    },
    data: { supplierId: supplierId, bvdId: bvdId }
  }

  let response = await customAxios(put)

  if (response) {
    return { success: true, data: response.data }
  } else {
    return {
      success: false,
      message: 'Something went wrong, try again'
    }
  }
}

async function updateCatalystJob (job) {
  const appId = sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : undefined

  var post = {
    method: 'POST',
    url: `${ApiAdmin.EndPoint}/apps/${appId}/scheduleJob/catalyst`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken()
    },

    data: {
      ...job
    }
  }

  let response = await customAxios(post)

  if (response) {
    return { success: true, data: response.data }
  } else {
    return {
      success: false,
      message: 'Something went wrong, try again'
    }
  }
}

async function getBatchStatus (callback) {
  const appId = sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : undefined

  var get = {
    method: 'GET',
    url: `${ApiAdmin.EndPoint}/apps/${appId}/supplier/batch`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken()
    }
  }

  let response = await customAxios(get)

  if (response) {
    const show = response.data ? response.data.show : true
    if (callback) callback(show)
    return { success: true, show: show }
  } else {
    if (callback) callback(false)
    return {
      success: false,
      message: 'Something went wrong, try again'
    }
  }
}
async function uploadBatch (batch) {
  const appId = sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : undefined

  var post = {
    method: 'POST',
    url: `${ApiAdmin.EndPoint}/apps/${appId}/supplier/batch`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken()
    },

    data: {
      batch: batch
    }
  }

  let response = await customAxios(post)

  if (response) {
    return { success: true, data: response.data }
  } else {
    return {
      success: false,
      message: 'Something went wrong, try again'
    }
  }
}
async function updateOrbisJob (job) {
  const appId = sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : undefined

  var post = {
    method: 'POST',
    url: `${ApiAdmin.EndPoint}/apps/${appId}/scheduleJob/orbis`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken()
    },

    data: {
      ...job
    }
  }

  let response = await customAxios(post)

  if (response) {
    return { success: true, data: response.data }
  } else {
    return {
      success: false,
      message: 'Something went wrong, try again'
    }
  }
}

async function updateApp (app) {
  const appId = sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : undefined
  let configurables = {}
  if (app && Object.keys(app).length > 0) {
    Object.keys(app).forEach(key => {
      if (app[key] && app[key].configurable) {
        configurables[key] = app[key]
      }
    })
  }

  var put = {
    method: 'PUT',
    url: `${ApiAdmin.EndPoint}/apps/${appId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken()
    },

    data: {
      params: app.params,
      enabled: app.enabled,
      type: app.type,
      ...configurables
    }
  }

  let response = await customAxios(put)

  if (response) {
    return { success: true, data: response.data }
  } else {
    return {
      success: false,
      message: 'Something went wrong, try again'
    }
  }
}
async function getCatalystModelsWithCallBack (job, callback) {
  let models = await getCatalystModels(job)
  if (models && models.data && models.data.models && models.success) {
    callback(
      models.data.models.map(model => {
        return { label: model.Name, value: model.Id }
      })
    )
  } else callback([])
}
//
async function getCatalystModels (job) {
  const appId = sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : undefined
  var get = {
    method: 'GET',
    url: `${ApiAdmin.EndPoint}/apps/${appId}/catalyst/models`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken()
    }
  }
  if (job) {
    get.headers.jobEmail = job.jobSSOEmail.value
    get.headers.jobName = job.jobSSOFirstName.value
    get.headers.jobLastName = job.jobSSOLastName.value
  }

  let response = await customAxios(get)

  if (response) {
    return { success: true, data: response.data }
  } else {
    return {
      success: false,
      message: 'Something went wrong, try again'
    }
  }
}

async function getConnectedBvDSuppliers (info) {
  const appId = sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : undefined
  var get = {
    method: 'GET',
    params: {
      bvdId: info.bvdId,
      supplierName: info.companyName
    },
    url: `${ApiAdmin.EndPoint}/apps/${appId}/suppliers`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken()
    }
  }

  let response = await customAxios(get)

  if (response) {
    return { success: true, data: response.data }
  } else {
    return {
      success: false,
      message: 'Something went wrong, try again'
    }
  }
}

async function getSupplierDetailsWithCallback (supplierId, callback) {
  let supplier = await getSupplierDetails(supplierId)
  callback(supplier.data)
}
async function getSupplierDetails (supplierId) {
  const appId = sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : undefined
  var get = {
    method: 'GET',
    url: `${ApiAdmin.EndPoint}/apps/${appId}/supplier/${supplierId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken()
    }
  }

  let response = await customAxios(get)

  if (response) {
    return { success: true, data: response.data }
  } else {
    return {
      success: false,
      message: 'Something went wrong, try again'
    }
  }
}
async function getMissingBvDSuppliers () {
  const appId = sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : undefined
  var get = {
    method: 'GET',
    url: `${ApiAdmin.EndPoint}/apps/${appId}/suppliers/missing`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken()
    }
  }

  let response = await customAxios(get)

  if (response) {
    return { success: true, data: response.data }
  } else {
    return {
      success: false,
      message: 'Something went wrong, try again'
    }
  }
}
async function getMissingBvDSupplierwithCallback (callback) {
  let missing = await getMissingBvDSuppliers()
  callback(missing.data)
}

async function getCatalystAssesments (guid) {
  const appId = sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : undefined
  var get = {
    method: 'GET',
    url: `${ApiAdmin.EndPoint}/apps/${appId}/catalyst/assesment`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken()
    },
    params: {
      assessmentGuid: guid
    }
  }

  let response = await customAxios(get)

  if (response) {
    return { success: true, data: response.data }
  } else {
    return {
      success: false,
      message: 'Something went wrong, try again'
    }
  }
}
async function getCatalystAssesmentsWithCallback (guid, callback) {
  let assesments = await getCatalystAssesments(guid)
  let assesmentsObject = {}
  if (assesments.success) {
    if (assesments.data) {
      assesmentsObject.bvdId = assesments.data.bvdId
      assesmentsObject.aribaSupplierId = assesments.data.aribaSupplierId
      assesmentsObject.realm = assesments.data.realm
      assesmentsObject.name = assesments.data.assesments.name
      if (
        assesments.data.assesments.guid &&
        assesments.data.assesments.guid.length > 0
      ) {
        assesmentsObject.list = []
        for (
          let index = 0;
          index < assesments.data.assesments.guid.length;
          index++
        ) {
          if (assesments.data.assesments.guid[index] === guid) {
            assesmentsObject.main = {
              score: assesments.data.assesments.score[index],
              scoreLabel: assesments.data.assesments.scoreLabel[index],
              status: assesments.data.assesments.status[index],
              //model: 'Default',
              modifiedDate: assesments.data.assesments.modificationDate[index],
              id: assesments.data.assesments.guid[index],
              modificationUser:
                assesments.data.assesments.modificationUser[index],
              modificationDate:
                assesments.data.assesments.modificationDate[index],
              startedUser: assesments.data.assesments.startedUser[index],
              startedDate: assesments.data.assesments.startedDate[index]
            }
          } else {
            assesmentsObject.list.push({
              score: assesments.data.assesments.score[index],
              scoreLabel: assesments.data.assesments.scoreLabel[index],
              status: assesments.data.assesments.status[index],
              //model: 'Default',
              modifiedDate: assesments.data.assesments.modificationDate[index],
              id: assesments.data.assesments.guid[index],
              modificationUser:
                assesments.data.assesments.modificationUser[index],
              modificationDate:
                assesments.data.assesments.modificationDate[index],
              startedUser: assesments.data.assesments.startedUser[index],
              startedDate: assesments.data.assesments.startedDate[index]
            })
          }
        }
      }
    }
  }
  let models = await getCatalystModels()
  if (models && models.data && models.data.models && models.success)
    models = models.data.models
  else models = []
  callback(assesmentsObject, models)
}

async function getCatalystBySupplierIdAssesments (supplierId) {
  const appId = sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : undefined
  var get = {
    method: 'GET',
    url: `${ApiAdmin.EndPoint}/apps/${appId}/catalyst/supplier/${supplierId}/assessment`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken()
    }
  }

  let response = await customAxios(get)

  if (response) {
    return { success: true, data: response.data }
  } else {
    return {
      success: false,
      message: 'Something went wrong, try again'
    }
  }
}
async function getCatalystAssesmentsBySupplierIdWithCallback (
  supplierId,
  callback,
  guid = ''
) {
  let assesments = await getCatalystBySupplierIdAssesments(supplierId)

  let assesmentsObject = {}
  if (assesments.success) {
    if (assesments.data) {
      assesmentsObject.bvdId = assesments.data.bvdId
      assesmentsObject.aribaSupplierId = supplierId
      assesmentsObject.realm = assesments.data.realm
      assesmentsObject.name = assesments.data.assesments.name
      if (
        assesments.data.assesments.guid &&
        assesments.data.assesments.guid.length > 0
      ) {
        assesmentsObject.list = []
        for (
          let index = 0;
          index < assesments.data.assesments.guid.length;
          index++
        ) {
          if (assesments.data.assesments.guid[index] === guid) {
            assesmentsObject.main = {
              score: assesments.data.assesments.score[index],
              scoreLabel: assesments.data.assesments.scoreLabel[index],
              status: assesments.data.assesments.status[index],
              //model: 'Default',
              modifiedDate: assesments.data.assesments.modificationDate[index],
              id: assesments.data.assesments.guid[index],
              modificationUser:
                assesments.data.assesments.modificationUser[index],
              modificationDate:
                assesments.data.assesments.modificationDate[index],
              startedUser: assesments.data.assesments.startedUser[index],
              startedDate: assesments.data.assesments.startedDate[index]
            }
          } else {
            assesmentsObject.list.push({
              score: assesments.data.assesments.score[index],
              scoreLabel: assesments.data.assesments.scoreLabel[index],
              status: assesments.data.assesments.status[index],
              //model: 'Default',
              modifiedDate: assesments.data.assesments.modificationDate[index],
              id: assesments.data.assesments.guid[index],
              modificationUser:
                assesments.data.assesments.modificationUser[index],
              modificationDate:
                assesments.data.assesments.modificationDate[index],
              startedUser: assesments.data.assesments.startedUser[index],
              startedDate: assesments.data.assesments.startedDate[index]
            })
          }
        }
      }
    }
  }
  let models = await getCatalystModels()
  if (models && models.data && models.data.models && models.success)
    models = models.data.models
  else models = []
  callback(assesmentsObject, models)
}
async function createNewConfiguration (newApp) {
  var get = {
    method: 'POST',
    url: `${ApiAdmin.EndPoint}/apps`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken()
    },
    data: { ...newApp }
  }

  let response = await customAxios(get)

  if (response) {
    return { success: true, data: response.data }
  } else {
    return {
      success: false,
      message: 'Something went wrong, try again'
    }
  }
}

async function getTenantMappings (type) {
  const appId = sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : undefined
  var get = {
    method: 'GET',
    url: `${ApiAdmin.EndPoint}/apps/${appId}/mapping`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken()
    },
    params: {
      mappingType: type
    }
  }

  let response = await customAxios(get)

  if (response) {
    return { success: true, data: response.data }
  } else {
    return {
      success: false,
      message: 'Something went wrong, try again'
    }
  }
}

async function getConfigurations (appId) {
  var get = {
    method: 'GET',
    url: `${ApiAdmin.EndPoint}/apps/${appId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken()
    }
  }

  let response = await customAxios(get)

  if (response) {
    return { success: true, data: response.data }
  } else {
    return {
      success: false,
      message: 'Something went wrong, try again'
    }
  }
}

//

async function getOrbisLists () {
  const appId = sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : undefined
  var get = {
    method: 'GET',
    url: `${ApiAdmin.EndPoint}/apps/${appId}/orbis/lists`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken()
    }
  }
  let errorResponse
  let response = await customAxios(get).catch(function (error) {
    console.log(error)
    errorResponse = error
  })

  if (response && response.data) {
    return { success: true, data: response.data.lists }
  } else {
    if (
      errorResponse &&
      errorResponse.response &&
      errorResponse.response.data
    ) {
      return {
        success: false,
        message: errorResponse.response.data.message,
        data: errorResponse.response.data.lists
      }
    } else
      return {
        success: false,
        message: 'Something went wrong, try again',
        data: response.data.lists
      }
  }
}

async function getOrbisListDetail (id, type) {
  const appId = sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : undefined
  var get = {
    method: 'GET',
    url: `${ApiAdmin.EndPoint}/apps/${appId}/orbis/list/${id}/type/${type}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken()
    }
  }
  let errorResponse
  let response = await customAxios(get).catch(function (error) {
    console.log(error)
    errorResponse = error
  })

  if (response && response.data) {
    return response.data.data
  } else {
    if (
      errorResponse &&
      errorResponse.response &&
      errorResponse.response.data
    ) {
      return {
        success: false,
        message: errorResponse.response.data.message,
        data: errorResponse.response.data.data
      }
    } else
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
  }
}
async function getListCallback (setValues) {
  let lists = await getOrbisLists()
  let listError
  if (lists.success && lists.data) {
    lists = lists.data.lists
  } else {
    listError = lists.message
    lists = []
  }
  setValues(lists, listError)
}

async function getMappingsAndLists (type, setValues) {
  let mappings = await getTenantMappings(type)

  // let listError

  let job
  if (mappings.success) {
    job = mappings.data.jobConfigurations
    mappings = mappings.data.data
  } else mappings = []
  /*
  if (lists.success && lists.data) {
    lists = lists.data.lists
  } else {
    listError = lists.message
    lists = []
  }*/

  if (setValues) setValues(mappings, job)
}

async function getOrbisContext (setContext) {
  const appId = sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : undefined
  var get = {
    method: 'GET',
    url: `${ApiAdmin.EndPoint}/apps/${appId}/orbis/context`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken()
    }
  }

  let response = await customAxios(get)

  if (response && response.data) {
    if (setContext) setContext(response.data.context)
    return { success: true, data: response.data.context }
  } else {
    return {
      success: false,
      message: 'Something went wrong, try again'
    }
  }
}

async function updateAppName (newAppName, callback) {
  const appId = sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(sessionStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
    ? JSON.parse(localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)).value
    : undefined

  var post = {
    method: 'POST',
    url: `${ApiAdmin.EndPoint}/apps/${appId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken()
    },
    data: { newAppName: newAppName }
  }

  let response = await customAxios(post)

  if (response && response.data) {
    if (callback) callback(response.data.data)
    return { success: true, data: response.data.data }
  } else {
    return {
      success: false,
      message: 'Something went wrong, try again'
    }
  }
}

async function getApplicationList (callback, forceGet = false) {
  let loggedIn
  try {
    await Auth.currentAuthenticatedUser()

    loggedIn = true
  } catch {
    loggedIn = false
  }
  if (
    (loggedIn && !localStorage.getItem(`BvD.AppList.${getTenant()}`)) ||
    forceGet
  ) {
    var get = {
      method: 'GET',
      url: `${ApiAdmin.EndPoint}/apps`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken()
      }
    }

    let response = await customAxios(get).catch(function (error) {
      console.log(error)
    })

    if (response && response.data) {
      if (callback) callback(response.data.data)
      return { success: true, data: response.data.data }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } else {
    if (callback) callback()
    return { success: true }
  }
}

const apiApps = {
  getOrbisContext,
  getSupplierData,
  getTenantMappings,
  setTenantMappings,
  getOrbisLists,
  getMappingsAndLists,
  setSupplierBvdId,
  getMissingBvDSupplierwithCallback,
  getCatalystAssesmentsWithCallback,
  getSupplierDetailsWithCallback,
  getCatalystAssesmentsBySupplierIdWithCallback,
  startCatalystAssesment,
  getConfigurations,
  getApplicationList,
  getConnectedBvDSuppliers,
  updateApp,
  updateCatalystJob,
  updateOrbisJob,
  createNewConfiguration,
  validateConnectivity,
  updateAppName,
  getCatalystModelsWithCallBack,
  uploadBatch,
  getBatchStatus,
  getOrbisListDetail,
  getListCallback
}

export default apiApps
