import React from 'react'
import PropTypes from 'prop-types'
import validator from 'validator'
import { Link } from 'react-router-dom'

// Core
import FormControl from '@material-ui/core/FormControl'
import Hidden from '@material-ui/core/Hidden'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Slide from '@material-ui/core/Slide'
import FormLabel from '@material-ui/core/FormLabel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
//import Select from '@material-ui/core/Select'
import Select from 'react-select'
import InputLabel from '@material-ui/core/InputLabel'
import Switch from '@material-ui/core/Switch'

// Icons
import CheckBox from '@material-ui/icons/CheckBox'
import Cancel from '@material-ui/icons/Cancel'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import Info from '@material-ui/icons/Info'

// Styles
import withStyles from '@material-ui/core/styles/withStyles'
import style from '../../assets/jss/material-dashboard-pro-react/views/Apps/widgetFormStyle'

// Components
import CustomInput from '../../components/CustomInput/CustomInput'

import { getSelectValue } from '../../utils/functions'
// import CustomTreeTable from '../../components/CustomTreeTable/CustomTreeTable'

// Connection
// import {
//   validateAppConnection,
//   validateCustomAppConnection
// } from '../../services/apiApps.js'

// Views
import ParametersAccordion from './ParametersAccordion'
import { env } from '../../utils/Constants'

// Api Calls
import apiApps from '../../services/apiApps'
import apiAppsProd from '../../services/apiAppsProd'
import { Label } from 'aws-amplify-react'
const validateConnectivity =
  env === 'local'
    ? apiApps.validateConnectivity
    : apiAppsProd.validateConnectivity

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

class connectivity extends React.Component {
  constructor (props) {
    super(props)

    this.state = props.initialState
    this.state.hasErrors = false
    this.state.expanded = []
    let mapping = this.state.conectivityMapping
    mapping.sortedFields = []
    this.state.expanded = []
    let fieldKeys = Object.keys(mapping.fields)
    if (mapping.fields && fieldKeys.length > 0) {
      fieldKeys.forEach(key => {
        if (mapping.fields[key].type === 'array') {
          mapping.fields[key].arrayId = this.state.expanded.length
          this.state.expanded.push({ expanded: true })
        }
        if (mapping.fields[key].parentObject) {
          mapping.fields[key].expandedAccordion = true
          mapping.fields[key].version = 1
        }
      })
      mapping.sortedFields = Object.keys(mapping.fields).sort(function (a, b) {
        let orderA = parseInt(mapping.fields[a].order)
        if (!parseInt(mapping.fields[a].order)) {
          orderA = 50000
        }
        let orderB = parseInt(mapping.fields[b].order)
        if (!parseInt(mapping.fields[b].order)) {
          orderB = 50000
        }

        return orderA - orderB
      })
    }
    this.state.dataVersion = 1
    this.state.conectivityMapping = mapping
    if (this.state.conectivityMapping.tableId)
      this.state.data = this.getTableData(this.state.conectivityMapping.tableId)
    else this.state.data = []
  }

  refreshTable = () => {}
  refreshTableWithData = () => {
    this.refreshTable(this.getTableData(this.state.conectivityMapping.tableId))
  }
  getParentObjectValue () {
    let value = undefined
    if (this.state.conectivityMapping.fields) {
      let keys = Object.keys(this.state.conectivityMapping.fields)
      keys.forEach(element => {
        let childElement = this.state.conectivityMapping.fields[element]
        if (childElement.isParentObjectValue) {
          value = childElement.value
          return
        } else if (childElement.isNegativeParentObjectValue) {
          value = !childElement.value
          return
        }
      })
    }
    return value
  }
  componentDidMount () {
    /*  this.refreshTableWithData = this.refreshTableWithData.bind(this)
    if (this.props.initialState.conectivityMapping.refreshTable)
      this.props.initialState.conectivityMapping.refreshTable(
        this.refreshTableWithData
      )*/
  }
  componentDidUpdate (prevProps, prevState) {
    if (
      (this.state.dataVersion !== prevState.dataVersion &&
        this.state.conectivityMapping.tableId) ||
      (this.props.initialState.conectivityMapping.fields !==
        this.state.conectivityMapping.fields &&
        this.state.conectivityMapping.tableId)
    ) {
      let newState = this.state
      newState.conectivityMapping.fields = this.props.initialState.conectivityMapping.fields
      newState.data = this.getTableData(this.state.conectivityMapping.tableId)
      newState.data.dataVersion = this.state.dataVersion
      if (this.state.dataVersion === prevState.dataVersion)
        newState.dataVersion = newState.dataVersion + 1
      this.setState(newState)
      // this.refreshTable(newState.data)
    } else if (
      this.props.initialState.conectivityMapping.fields !==
      this.state.conectivityMapping.fields
    ) {
      let newState = this.state
      newState.conectivityMapping.fields = this.props.initialState.conectivityMapping.fields
      this.setState(newState)
    }
    /*   this.refreshTableWithData = this.refreshTableWithData.bind(this)
    if (this.props.initialState.conectivityMapping.refreshTable)
      this.props.initialState.conectivityMapping.refreshTable(
        this.refreshTableWithData
      )*/
  }

  sendState () {
    return this.state
  }
  onMappingFieldChange2 (field, valueId, value, fieldId, validate = true) {
    if (field && valueId) {
      field[valueId] = value
      let stateCopy = this.state
      this.setState(stateCopy)
      if (validate) {
        this.validateField2(this.state.conectivityMapping.fields, fieldId)
      }
    }
  }
  flickerError2 (
    field,
    id,
    errorMessage,
    times = 2,
    parentId = undefined,
    on = true
  ) {
    let element = document.getElementById(
      field.label + 'showErrorIconDiv' + parentId
    )
    if (times > 0) {
      if (element) {
        element.style.display = 'block'
        if (on) {
          element.style.visibility = 'visible'
        } else element.style.visibility = 'hidden'
      }

      times--
      setTimeout(() => {
        this.flickerError2(field, id, errorMessage, times, parentId, !on)
      }, 500)
    } else if (element) {
      element.style.display = 'block'
      element.style.visibility = 'visible'
    }
  }

  showError2 (
    field,
    errorMessage,
    focus,
    flicker = false,
    parentId,
    fieldId,
    times = 4
  ) {
    this.onMappingFieldChange2(
      field,
      'errorMessage',
      errorMessage,
      fieldId,
      false
    )
    this.onMappingFieldChange2(field, 'error', true, fieldId, false, parentId)
    if (focus) {
      let element = document.getElementById(
        field.label + 'Formlabel' + parentId
      )
      if (element) {
        if (element.scrollIntoView) {
          element.scrollIntoView()
          setTimeout(() => {
            let element = document.getElementById(
              field.label + 'Formlabel' + parentId
            )
            if (element) {
              if (element.scrollIntoView) {
                element.scrollIntoView()
              }
            }
          }, 850)
        }
      }
    }
    if (!flicker) times = 0
    this.flickerError2(field, fieldId, errorMessage, times, parentId)
  }

  checkConnection = async () => {
    this.setState({ connected: undefined })
    this.setState({ connectionTesting: true })
    this.validateEndingCharacter()

    let connection = await validateConnectivity({
      parentId: this.state.parentId,
      ...this.state.conectivityMapping.fields,
      region: this.props.initialState.conectivityMapping.region.value
    })

    let defaultError = {
      error: 'invalid_request',
      error_description: 'Connection Error'
    }
    defaultError = JSON.stringify(defaultError, null, '\t')

    if (connection && connection.success) {
      this.setState({ connected: connection.data.data.valid })
      if (connection.data.data.valid) {
        this.setState({ connectedMessage: undefined })
      } else {
        this.setState({
          connectedMessage: JSON.stringify(connection.data.data) || defaultError
        })
      }
    } else {
      this.setState({ connected: false })
      this.setState({ connectedMessage: defaultError })
    }
    this.setState({ connectionTesting: false })
  }
  showErrorOnArray (field, message, focus, flicker, parentId, index, key) {
    this.showError2(field, message, focus, flicker, parentId + (index + 1))
    this.onMappingFieldChange2(field, 'errorKey', key, undefined, false)
  }
  resetArrayErrors (field) {
    this.onMappingFieldChange2(field, 'error', false, undefined, false)
    this.onMappingFieldChange2(field, 'errorMessage', '', undefined, false)
    this.onMappingFieldChange2(field, 'errorKey', undefined, undefined, false)
  }
  validateField2 (
    parentObject,
    fieldId,
    currentValidatedValue = true,
    focus = false,
    flicker = false,
    parentId = undefined
  ) {
    if (!parentId) parentId = this.props.initialState.parentId
    if (fieldId !== this.state.conectivityMapping.tableId) {
      let field = parentObject[fieldId]
      if (typeof field === 'object') {
        if (field.parentObject) {
          let returnValue = true
          let keys = Object.keys(field)
          keys.forEach(key => {
            currentValidatedValue = this.validateField2(
              parentObject[fieldId],
              key,
              currentValidatedValue,
              focus,
              flicker,
              fieldId
            )
            if (!currentValidatedValue) {
              field.expandedAccordion = true
              if (field.version !== undefined) field.version = field.version + 1
              else field.version = 1

              returnValue = false
            }
          })
          return returnValue
        }
        if (field.isArrayChild) {
          let returnValue = true
          if (this.state.conectivityMapping.fields) {
            Object.keys(this.state.conectivityMapping.fields).forEach(id => {
              if (this.state.conectivityMapping.fields[id].type === 'array')
                returnValue = this.validateField2(
                  this.state.conectivityMapping.fields,
                  id,
                  returnValue,
                  true,
                  true
                )
            })
          }
          return returnValue
        }
        if (field.type === 'array') {
          let returnValue = true
          if (field.validations) {
            let keys = Object.keys(field.validations)
            for (let index = 0; index < keys.length; index++) {
              const key = keys[index]

              for (let i = 0; i < field.value.length; i++) {
                if (field.validations[key].isHTTPSURL) {
                  if (
                    !validator.isURL(field.value[i][key], {
                      protocols: ['https'],
                      require_protocol: true
                    })
                  ) {
                    this.showErrorOnArray(
                      field.value[i],
                      'Must be an https URL ',
                      currentValidatedValue && focus,
                      flicker,
                      parentId,
                      i,
                      key
                    )
                    returnValue = false
                  } else {
                    this.resetArrayErrors(field.value[i])
                  }
                } else if (field.validations[key].isURL) {
                  if (!validator.isURL(field.value[i][key])) {
                    this.showErrorOnArray(
                      field.value[i],
                      'Must be an URL ',
                      currentValidatedValue && focus,
                      flicker,
                      parentId,
                      i,
                      key
                    )
                    returnValue = false
                  } else {
                    this.resetArrayErrors(field.value[i])
                  }
                } else if (field.validations[key].starts) {
                  if (
                    !field.value[i][key].startsWith(
                      field.validations[key].starts
                    )
                  ) {
                    this.showErrorOnArray(
                      field.value[i],
                      'Must Start With ' + field.validations[key].starts,
                      currentValidatedValue && focus,
                      flicker,
                      parentId,
                      i,
                      key
                    )

                    returnValue = false
                  } else {
                    this.resetArrayErrors(field.value[i])
                  }
                } else if (field.validations[key].required) {
                  if (!field.value[i][key])
                    this.showErrorOnArray(
                      field.value[i],
                      'Required',
                      currentValidatedValue && focus,
                      flicker,
                      parentId,
                      i,
                      key
                    )
                  else {
                    this.resetArrayErrors(field.value[i])
                  }
                }
              }
            }
          }
          return returnValue
        }
        let value = field.value
        if (field.type !== 'password' && value) {
          if (value.trim) value = value.trim()
        }

        if (
          (field.required && !value && typeof value === 'string') ||
          (field.required && value === null)
        ) {
          this.showError2(
            field,
            'Field is Required',
            currentValidatedValue && focus,
            flicker,
            parentId
          )
          return false
        } else if (field.type === 'int') {
          if (
            !isNaN(Number(field.value)) &&
            Number.isInteger(Number(field.value))
          )
            field.value = Number(field.value)
          else {
            this.showError2(
              field,
              'Field is not an Integer',
              currentValidatedValue && focus,
              flicker,
              parentId
            )
            return false
          }
        } else if (field.type === 'numeric') {
          if (!isNaN(Number(field.value))) field.value = Number(field.value)
          else {
            this.showError2(
              field,
              'Field is not a number',
              currentValidatedValue && focus,
              flicker,
              parentId
            )
            return false
          }
        }
        let element = document.getElementById(
          field.label + 'showErrorIconDiv' + parentId
        )
        if (element) {
          element.style.display = 'none'
        }
        this.onMappingFieldChange2(field, 'error', false, fieldId, false)
        this.onMappingFieldChange2(field, 'errorMessage', '', fieldId, false)
      }
    }
    return currentValidatedValue
  }

  validateEndingCharacter () {
    Object.keys(this.state.conectivityMapping.fields).forEach(id => {
      //endingCharacter
      if (this.state.conectivityMapping.fields[id].endingCharacter) {
        if (
          this.state.conectivityMapping.fields[id].value &&
          this.state.conectivityMapping.fields[id].value.length > 0
        ) {
          if (
            this.state.conectivityMapping.fields[id].value[
              this.state.conectivityMapping.fields[id].value.length - 1
            ] !== this.state.conectivityMapping.fields[id].endingCharacter[0]
          ) {
            this.onMappingFieldChange(
              'value',
              this.state.conectivityMapping.fields[id].value +
                this.state.conectivityMapping.fields[id].endingCharacter[0],
              id,
              false
            )
          }
        }
      }
    })
  }
  isValidated () {
    let validated = true
    this.validateEndingCharacter()
    //let validation = validateObject(connectivitySchema, this.state)
    if (this.innerReferences !== undefined) {
      for (let index = 0; index <= this.innerReferences; index++) {
        if (this[index] && this[index].isValidated) {
          let indexIsValidated = this[index].isValidated()

          if (validated && !indexIsValidated) validated = false
        }
      }
    }
    if (this.state.conectivityMapping.fields) {
      Object.keys(this.state.conectivityMapping.fields).forEach(id => {
        validated = this.validateField2(
          this.state.conectivityMapping.fields,
          id,
          validated,
          true,
          true
        )
      })
    }

    return validated
  }

  showModal () {
    const { classes } = this.props
    return (
      <Dialog
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal
        }}
        open={this.state.showModal === true}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.setState({ showModal: false })}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>Error connection details</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {this.state.connectionErrorMessage}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => this.setState({ showModal: false })}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  customDBListFields (fields, parrentKey, classes, arrayKey) {
    let newObject = { ...fields.newObject }
    let newObjectOrder = {}
    if (fields.newObjectOrder) newObjectOrder = { ...fields.newObjectOrder }
    else newObjectOrder = { ...fields.newObject }
    let keys = Object.keys(newObjectOrder).sort(function (a, b) {
      let orderA = parseInt(newObjectOrder[a])
      if (!parseInt(newObjectOrder[a])) {
        orderA = 50000
      }
      let orderB = parseInt(newObjectOrder[b])
      if (!parseInt(newObjectOrder[b])) {
        orderB = 50000
      }

      return orderA - orderB
    })
    let parentObjectValue = this.getParentObjectValue()
    let lock = !(
      (parentObjectValue && parentObjectValue === true) ||
      parentObjectValue !== false
    )
    return (
      <Accordion
        disabled={lock}
        expanded={
          lock
            ? false
            : this.state.expanded
            ? this.state.expanded[fields.arrayId].expanded
            : false
        }
        onChange={() => {
          if (
            this.state.expanded &&
            this.state.expanded[fields.arrayId] !== undefined
          ) {
            let newState = this.state
            newState.expanded[fields.arrayId].expanded = !newState.expanded[
              fields.arrayId
            ].expanded
            this.setState(newState)
          }
        }}
        style={
          !lock
            ? { width: '100%' }
            : {
                backgroundColor: 'rgba(0, 0, 0, 0.03)',
                width: '100%'
              }
        }
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{fields.label}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid container style={{ margin: 'auto' }}>
              <Grid item xs={12} style={{ margin: 'auto 0' }}>
                <div style={{ width: '100%' }}>
                  <IconButton
                    style={{ float: 'right' }}
                    onClick={() => {
                      fields.value.push(newObject)
                      let state = this.state
                      this.setState(state)
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {fields.value.map((field, id) => {
                return (
                  <Grid container style={{ margin: 'auto' }} key={id}>
                    {keys.map((key, index) => {
                      field.label = key.charAt(0).toUpperCase() + key.slice(1)
                      field.isArrayChild = true
                      return (
                        <Grid
                          item
                          xs={Math.ceil(parseInt(11 / keys.length))}
                          style={{
                            textAlign: 'center'
                          }}
                          key={key + index + id}
                        >
                          {this.customDBFields(
                            field,
                            index,
                            classes,
                            id,
                            key,
                            true,
                            true,
                            index,
                            undefined,
                            undefined,
                            arrayKey
                          )}
                        </Grid>
                      )
                    })}

                    <Grid item xs={1} style={{ margin: 'auto 0' }}>
                      <div style={{ width: '100%' }}>
                        <IconButton
                          style={{ float: 'right' }}
                          onClick={() => {
                            fields.value.splice(id, 1)
                            let state = this.state
                            this.setState(state)
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    )
  }
  customDBFields (
    field,
    key,
    classes,
    id,
    valueId = 'value',
    fullWidth = false,
    hideTopLabel = false,
    aditionalLabelId = '',
    mutualExclusiveField = undefined,
    mutualExclusiveFieldId = undefined,
    arraykey = undefined
  ) {
    if (field.showDependantField) {
      if (
        !this.state.conectivityMapping.fields[field.showDependantField] ||
        this.state.conectivityMapping.fields[field.showDependantField].value !==
          field.showDependantValue
      ) {
        return ''
      }
    }
    if (field.hideDependantField) {
      if (
        this.state.conectivityMapping.fields[field.hideDependantField] &&
        this.state.conectivityMapping.fields[field.hideDependantField].value ===
          field.hideDependantValue
      ) {
        return ''
      }
    }
    let booleanValue

    if (field.type === 'boolean')
      booleanValue =
        field[valueId] && typeof field.value === 'string'
          ? field[valueId].toLowerCase() === 'false'
          : field[valueId]
          ? field[valueId]
          : false
    return (
      <Grid item xs={12} sm={12} md={12} key={key + id}>
        <Grid container>
          <Grid item xs={12} sm={field.type === 'array' || fullWidth ? 12 : 6}>
            <div
              className={classes.displayTable}
              style={{ paddingTop: '20px' }}
            >
              {field.error ? (
                <div
                  id={
                    field.label
                      ? field.label +
                        'showErrorIconDiv' +
                        this.props.initialState.parentId +
                        aditionalLabelId
                      : valueId +
                        'showErrorIconDiv' +
                        this.props.initialState.parentId +
                        aditionalLabelId
                  }
                  style={
                    hideTopLabel && field.errorKey && field.errorKey === valueId
                      ? { paddingBottom: '15px', paddingTop: '15px' }
                      : field.errorKey
                      ? {
                          paddingBottom: '15px',
                          visibility: 'hidden',
                          paddingTop: '15px'
                        }
                      : {}
                  }
                >
                  {field.errorMessage ? (
                    <Tooltip title={field.errorMessage}>
                      <ErrorOutlineIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title={''}>
                      <ErrorOutlineIcon />
                    </Tooltip>
                  )}
                </div>
              ) : (
                ''
              )}

              {field.type === 'array' ? (
                ''
              ) : (
                <Hidden only={field.type !== 'boolean' ? ['xs'] : []}>
                  <div className={classes.verticalAlign}>
                    <FormLabel
                      id={
                        field.label
                          ? field.label +
                            'Formlabel' +
                            this.props.initialState.parentId +
                            aditionalLabelId
                          : valueId +
                            'Formlabel' +
                            this.props.initialState.parentId +
                            aditionalLabelId
                      }
                      style={
                        field.type === 'header'
                          ? {
                              fontWeight: 'bold',
                              color: 'black'
                            }
                          : {}
                      }
                      className={classes.inputLabel}
                    >
                      {hideTopLabel ? '' : field.label}
                    </FormLabel>
                  </div>
                </Hidden>
              )}
            </div>
          </Grid>
          {field.type === 'array' ? (
            <Grid item xs={12}>
              {this.customDBListFields(field, key, classes, id)}
            </Grid>
          ) : (
            <Grid item xs={12} sm={fullWidth ? 12 : 6}>
              {field.options && field.options.length > 0 ? (
                <div
                  style={{
                    width: '100%',
                    padding: '13px 0px 13px'
                  }}
                >
                  <FormControl
                    style={{ width: '100%' }}
                    className={classes.formControl}
                  >
                    <Grid container>
                      <Grid item xs={12} style={{ height: '20px' }}>
                        <InputLabel className={classes.inputLabel}>
                          {
                            <div>
                              <span>
                                {field.label}
                                <small>
                                  {field.required && !field.errorMessage
                                    ? '*'
                                    : ''}
                                </small>
                                <small>
                                  {field.errorMessage
                                    ? ' (' + field.errorMessage + ')'
                                    : ''}
                                </small>
                              </span>
                            </div>
                          }
                        </InputLabel>
                      </Grid>
                      <Grid item xs={10}>
                        <Select
                          styles={{
                            valueContainer: (provided, state) => {
                              return {
                                ...provided,
                                maxHeight: '150px',
                                overflowY: 'auto'
                              }
                            }
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={getSelectValue(
                            field[valueId],
                            field.options,
                            field
                          )}
                          onChange={e => {
                            let newValue
                            if (Array.isArray(e)) {
                              newValue = e.map(val => {
                                return val.value
                              })
                            } else newValue = e.value
                            if (newValue)
                              this.onMappingFieldChange2(
                                field,
                                valueId,
                                newValue
                              )
                          }}
                          isMulti={field.multiple}
                          options={field.options.map((element, count) => {
                            let label
                            let value
                            if (typeof element === 'object') {
                              if (element.label !== undefined)
                                label = element.label
                              if (element.value !== undefined)
                                value = element.value
                            } else {
                              value = element
                              label = element
                            }
                            if (value !== undefined && label !== undefined)
                              return { label: label, value: value }
                            else return ''
                          })}
                        ></Select>
                      </Grid>
                    </Grid>
                    {/*    <Select
                      style={{
                        width: '100%',
                        textAlign: 'center'
                      }}
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      multiple={field.multiple}
                      value={field[valueId]}
                      onChange={e =>
                        /*  this.onMappingFieldChange(
                      'value',
                      e.target.value,
                      id
                    )*/
                    /*
                        this.onMappingFieldChange2(
                          field,
                          valueId,
                          e.target.value
                        )
                      }
                    >
                      {field.options.map((element, count) => {
                        let label
                        let value
                        if (typeof element === 'object') {
                          if (element.label !== undefined) label = element.label
                          if (element.value !== undefined) value = element.value
                        } else {
                          value = element
                          label = element
                        }
                        if (value !== undefined && label !== undefined)
                          return (
                            <MenuItem
                              key={count}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              style={{
                                overflowX: 'auto',
                                textOverflow: 'ellipsis',
                                textAlign: 'center'
                              }}
                              value={value}
                            >
                              {label}
                            </MenuItem>
                          )
                        else return ''
                      })}
                    </Select>
               */}{' '}
                  </FormControl>
                </div>
              ) : field.type === 'boolean' ? (
                <Grid container justifycontent="center">
                  <div className={classes.block}>
                    <FormControlLabel
                      style={{ padding: '10px' }}
                      control={
                        <Switch
                          checked={booleanValue}
                          onChange={event => {
                            /*
                        this.onMappingFieldChange(
                          'value',
                          event.target.checked,
                          id,
                          false
                        )*/
                            if (mutualExclusiveField !== undefined) {
                              if (mutualExclusiveField.value)
                                this.onMappingFieldChange2(
                                  mutualExclusiveField,
                                  valueId,
                                  !event.target.checked,
                                  mutualExclusiveFieldId
                                )
                            }
                            this.onMappingFieldChange2(
                              field,
                              valueId,
                              event.target.checked,
                              id
                            )
                          }}
                          classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                    />
                  </div>
                </Grid>
              ) : field.type === 'route' ? (
                <Grid container justifycontent="center">
                  <div>
                    <Link to={field.route}>{field.value}</Link>
                  </div>
                </Grid>
              ) : field.type === 'header' ? (
                <div></div>
              ) : field.type === 'label' ? (
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      paddingTop: '40px'
                    }}
                  >
                    <Label style={{ fontWeight: 'bold' }}>{field.value}</Label>
                  </Grid>
                </Grid>
              ) : (
                <div style={{ width: '85%' }}>
                  <CustomInput
                    success={field.state === 'success'}
                    error={field.state === 'error'}
                    labelText={
                      <div className={classes.inputLabel}>
                        <span>
                          {field.label}
                          <small>
                            {field.required && !field.errorMessage ? '*' : ''}
                          </small>
                          <small>
                            {(field.errorMessage &&
                              field.errorKey &&
                              field.errorKey === valueId) ||
                            (field.errorMessage && !field.errorKey)
                              ? ' (' + field.errorMessage + ')'
                              : ''}
                          </small>
                        </span>
                      </div>
                    }
                    id={
                      field.label
                        ? field.label + 'ID' + this.props.initialState.parentId
                        : valueId + 'ID' + this.props.initialState.parentId
                    }
                    inputInternalProps={{
                      style: {
                        width: '100%',
                        textAlign: 'center'
                      }
                    }}
                    inputProps={{
                      type: field.type,
                      value: field[valueId],
                      onChange: event => {
                        this.onMappingFieldChange2(
                          field,
                          valueId,
                          event.target.value,
                          arraykey ? arraykey : id
                        )
                        /* this.onMappingFieldChange(
                    'value',
                    event.target.value,
                    id
                  )*/
                      }
                    }}
                  />
                </div>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  }
  getDataParentElements (
    parentElement,
    data,
    key = undefined,
    maxDrill = undefined,
    currentDrill = undefined
  ) {
    let element = parentElement
    if (!currentDrill) currentDrill = 0
    if (key) element = parentElement[key]
    if (typeof element === 'object') {
      let keys = Object.keys(element)
      if (keys) {
        //get children
        if (keys.length > 0) {
          let foundParrent = false
          keys.forEach(key2 => {
            if (!foundParrent)
              if (!maxDrill || currentDrill <= maxDrill) {
                foundParrent = this.getDataParentElements(
                  element,
                  data,
                  key2,
                  maxDrill,
                  currentDrill + 1
                )
                if (foundParrent) {
                  if (this.state.conectivityMapping.addParentElementKey) {
                    element.parentKey = key
                  }
                }
              }
          })
        }
      }
    } else {
      //remove "order key since this is used for the order of the components"
      if (key && key !== 'order') {
        if (Object.keys(parentElement).length > 1) {
          data.push(parentElement)
        }
        return true
      }
    }
  }

  getTableData (id) {
    const { classes } = this.props

    let data = []
    let parentData = []
    this.getDataParentElements(
      this.props.initialState.conectivityMapping.fields[id],
      parentData,
      undefined,
      this.props.initialState.conectivityMapping.maxDrill
    )
    let parentIndex = -1
    parentData.forEach(element => {
      parentIndex++
      let keys = Object.keys(element)
      if (keys) {
        let customElement = {}
        keys.forEach(elementKey => {
          if (typeof element[elementKey] === 'boolean') {
            customElement[elementKey] = (
              <div className="actions-center">
                <Switch
                  checked={element[elementKey]}
                  onChange={event => {
                    element[elementKey] = event.target.checked
                    if (
                      this.props.initialState.conectivityMapping
                        .onBooleanSwitchChange
                    )
                      this.props.initialState.conectivityMapping.onBooleanSwitchChange(
                        refresh => {
                          //debugger
                          // element[elementKey] = !element[elementKey]
                          //  this.setState(this.state)
                        }
                      )
                    //refresh on table
                    this.setState({ dataVersion: this.state.dataVersion + 1 })
                    // this.refreshTableWithData()
                  }}
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar
                  }}
                />
              </div>
            )
          } else customElement[elementKey] = element[elementKey]
        })
        if (
          this.props.initialState.conectivityMapping.tableActions &&
          this.props.initialState.conectivityMapping.tableActions.length &&
          this.props.initialState.conectivityMapping.tableActions.length > 0
        ) {
          let length = Number(
            (
              100 /
              this.props.initialState.conectivityMapping.tableActions.length
            ).toFixed(2)
          )

          customElement[
            'action'
          ] = this.props.initialState.conectivityMapping.tableActions.map(
            (action, index) => {
              return (
                <div
                  onClick={() => {
                    if (
                      this.props.initialState.conectivityMapping
                        .tableActionFunctions &&
                      this.props.initialState.conectivityMapping
                        .tableActionFunctions.length &&
                      this.props.initialState.conectivityMapping
                        .tableActionFunctions.length > index
                    )
                      this.props.initialState.conectivityMapping.tableActionFunctions[
                        index
                      ](element, parentData, parentIndex)
                  }}
                  style={{ width: `${length}%`, float: 'right' }}
                  key={index}
                >
                  {action}
                </div>
              )
            }
          )
        }

        data.push(customElement)
      }
    })

    return data
  }
  getTableData2 (id) {
    const { classes } = this.props

    let data = []
    let parentData = []
    this.getDataParentElements(
      this.state.conectivityMapping.fields[id],
      parentData,
      undefined,
      this.state.conectivityMapping.maxDrill
    )
    let parentIndex = -1
    parentData.forEach(element => {
      parentIndex++
      let keys = Object.keys(element)
      if (keys) {
        let customElement = {}
        keys.forEach(elementKey => {
          if (typeof element[elementKey] === 'boolean') {
            customElement[elementKey] = (
              <div className="actions-center">
                <Switch
                  checked={element[elementKey]}
                  onChange={event => {
                    element[elementKey] = event.target.checked
                    this.refreshTableWithData()
                    if (this.state.conectivityMapping.onBooleanSwitchChange)
                      this.state.conectivityMapping.onBooleanSwitchChange(
                        refresh => {
                          element[elementKey] = !element[elementKey]
                          this.setState(this.state)
                          if (refresh) refresh()
                        }
                      )
                  }}
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar
                  }}
                />
              </div>
            )
          } else customElement[elementKey] = element[elementKey]
        })
        if (
          this.state.conectivityMapping.tableActions &&
          this.state.conectivityMapping.tableActions.length &&
          this.state.conectivityMapping.tableActions.length > 0
        ) {
          let length = Number(
            (100 / this.state.conectivityMapping.tableActions.length).toFixed(2)
          )

          customElement[
            'action'
          ] = this.state.conectivityMapping.tableActions.map(
            (action, index) => {
              return (
                <div
                  onClick={() => {
                    if (
                      this.state.conectivityMapping.tableActionFunctions &&
                      this.state.conectivityMapping.tableActionFunctions
                        .length &&
                      this.state.conectivityMapping.tableActionFunctions
                        .length > index
                    )
                      this.state.conectivityMapping.tableActionFunctions[index](
                        element,
                        parentData,
                        parentIndex
                      )
                  }}
                  style={{ width: `${length}%`, float: 'right' }}
                  key={index}
                >
                  {action}
                </div>
              )
            }
          )
        }

        data.push(customElement)
      }
    })

    return data
  }
  getTableColumns (id, examplePosition = 0) {
    let keys = Object.keys(this.state.conectivityMapping.fields[id])
    if (keys.length > examplePosition) {
      let example = this.state.conectivityMapping.fields[id][
        keys[examplePosition]
      ]
      if (typeof example !== 'object')
        return this.getTableColumns(id, examplePosition + 1)
      let columns = Object.keys(example).map((id2, key2) => {
        let column = {
          Header: id2,
          accessor: id2
        }
        return column
      })

      return columns
    } else return []
  }
  getBackButton () {
    if (this.state.conectivityMapping.backButtonOnClick)
      return (
        <div
          style={{
            width: '100%'
          }}
        >
          <IconButton onClick={this.state.conectivityMapping.backButtonOnClick}>
            <KeyboardBackspaceIcon />
          </IconButton>
        </div>
      )
    return ''
  }
  render () {
    const { classes } = this.props
    if (!this.state.conectivityMapping)
      return (
        <Grid container justifycontent="center">
          {this.getBackButton()}
          <Grid item xs={12} sm={12} md={12}>
            no Mapping Prop
          </Grid>
        </Grid>
      )
    else if (!this.state.conectivityMapping.fields) {
      return (
        <Grid item xs={12} sm={12} md={12}>
          {this.getBackButton()}
          no fields
        </Grid>
      )
    } else if (!this.state.conectivityMapping.service) {
      return (
        <Grid item xs={12} sm={12} md={12}>
          {this.getBackButton()}
          no service
        </Grid>
      )
    } else
      return (
        <form autoComplete="off" style={{ width: '99%', margin: 'auto' }}>
          <Grid container justifycontent="center">
            {this.getBackButton()}
            {this.state.conectivityMapping.documentationPath ? (
              <Grid item xs={12} sm={12} md={12}>
                <Button
                  simple
                  className={classes.documentation}
                  href={this.state.conectivityMapping.documentationPath}
                  target="_blank"
                >
                  <Info className={classes.icons} />
                  Download Documentation
                </Button>
              </Grid>
            ) : (
              ''
            )}
            {this.state.conectivityMapping.title ? (
              <Grid item xs={12} sm={12}>
                <h4 className={classes.infoText}>
                  {this.state.conectivityMapping.title}
                </h4>
              </Grid>
            ) : (
              ''
            )}
            <Grid item xs={12} sm={12}>
              {this.state.conectivityMapping.hideServiceName ? (
                ''
              ) : this.state.conectivityMapping.serviceLabel ? (
                <h5 className={classes.sectionTitle}>
                  {this.state.conectivityMapping.serviceLabel}
                </h5>
              ) : (
                <h5
                  className={classes.sectionTitle}
                  style={{ textTransform: 'capitalize' }}
                >
                  {this.state.conectivityMapping.service}
                </h5>
              )}
              <Grid item xs={12}>
                {this.props.initialState.conectivityMapping.topChildren
                  ? this.props.initialState.conectivityMapping.topChildren
                  : ''}
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                {this.state.conectivityMapping.fields ? (
                  <Grid container>
                    {this.state.conectivityMapping.sortedFields.map(
                      (id, key) => {
                        if (
                          (this.state.conectivityMapping.ignoreIds &&
                            this.state.conectivityMapping.ignoreIds.includes(
                              id
                            )) ||
                          this.state.conectivityMapping.fields[id].hidden
                        )
                          return ''
                        else if (
                          typeof this.state.conectivityMapping.fields[id] ===
                            'object' &&
                          this.state.conectivityMapping.fields[id].parentObject
                        ) {
                          return (
                            <Grid item xs={12} sm={12} md={12} key={key}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <div
                                    key={key}
                                    style={{ width: '100%', margin: 'auto' }}
                                  >
                                    <div
                                      style={{ width: '98%', margin: 'auto' }}
                                    >
                                      <ParametersAccordion
                                        innerRef={node => {
                                          if (this.innerReferences)
                                            this.innerReferences++
                                          else this.innerReferences = 0
                                          this[this.innerReferences] = node
                                        }}
                                        initialState={{
                                          forceAccordion: true,
                                          parentObject: this.state
                                            .conectivityMapping.fields,
                                          parameters: [
                                            {
                                              version: this.state
                                                .conectivityMapping.fields[id]
                                                .version,

                                              expandedAccordion: this.state
                                                .conectivityMapping.fields[id]
                                                .expandedAccordion,
                                              parentId: id,
                                              title: this.state
                                                .conectivityMapping.fields[id]
                                                .label,
                                              conectivityMapping: {
                                                siteUrl: this.props.initialState
                                                  .conectivityMapping.siteUrl,
                                                region: this.props.initialState
                                                  .conectivityMapping.region,
                                                env: this.props.initialState
                                                  .conectivityMapping.env,
                                                app: this.props.initialState
                                                  .conectivityMapping.app,
                                                hideServiceName: true,
                                                service: this.state
                                                  .conectivityMapping.service,
                                                hideTestConnection: true,
                                                fields: this.state
                                                  .conectivityMapping.fields[id]
                                              }
                                            }
                                          ]
                                        }}
                                      ></ParametersAccordion>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                        } else if (
                          typeof this.state.conectivityMapping.fields[id] ===
                          'object'
                        ) {
                          return this.customDBFields(
                            this.state.conectivityMapping.fields[id],
                            key,
                            classes,
                            id,
                            'value',
                            false,
                            false,
                            '',
                            this.state.conectivityMapping.fields[id][
                              'mutualExclusive'
                            ]
                              ? this.state.conectivityMapping.fields[
                                  this.state.conectivityMapping.fields[id][
                                    'mutualExclusive'
                                  ]
                                ]
                              : undefined,
                            this.state.conectivityMapping.fields[id][
                              'mutualExclusive'
                            ]
                          )
                        } else return ''
                      }
                    )}
                    {!this.state.conectivityMapping.hideTestConnection ? (
                      <Grid item xs={12} sm={12} md={12}>
                        <Tooltip
                          title={
                            'Check connection to ' +
                            this.state.conectivityMapping.service
                          }
                        >
                          <Button
                            variant="contained"
                            onClick={this.checkConnection.bind(this)}
                            className={classes.documentation}
                            style={
                              this.state.connected !== undefined
                                ? this.state.connected
                                  ? {
                                      backgroundColor: '#001489',
                                      color: 'white'
                                    }
                                  : { backgroundColor: 'red', color: 'white' }
                                : { backgroundColor: '#001489', color: 'white' }
                            }
                          >
                            {this.state.connected !== undefined ? (
                              this.state.connected ? (
                                <div>
                                  <IconButton
                                    style={{
                                      color: 'white',
                                      fontSize: '0.85rem'
                                    }}
                                  >
                                    <CheckBox></CheckBox>
                                    Connected
                                  </IconButton>
                                </div>
                              ) : (
                                <div>
                                  <IconButton
                                    style={{
                                      color: 'white',
                                      fontSize: '0.85rem'
                                    }}
                                  >
                                    <Cancel className={classes.icons} />
                                    Connection Error
                                  </IconButton>
                                </div>
                              )
                            ) : (
                              <div>
                                {this.state.connectionTesting
                                  ? '...'
                                  : 'Test Connectivity'}
                              </div>
                            )}
                          </Button>
                        </Tooltip>
                      </Grid>
                    ) : (
                      ''
                    )}
                    <Grid item xs={12} sm={12} md={12}>
                      {this.state.connectedMessage ? (
                        <Tooltip title="View connection error details">
                          <Button
                            color="danger"
                            simple
                            className={classes.documentation}
                            onClick={() =>
                              this.setState({
                                showModal: true,
                                connectionErrorMessage: this.state
                                  .connectedMessage
                              })
                            }
                          >
                            Error details
                          </Button>
                        </Tooltip>
                      ) : null}
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
            {this.showModal()}
          </Grid>
        </form>
      )
  }
}

connectivity.propTypes = {
  classes: PropTypes.object
}

export default withStyles(style)(connectivity)
