import defaultStyle from '../Default'

const suppliersStyle = {
  ...defaultStyle,
  card: {
    ...defaultStyle.card,
    width: '650px',
    height: '67px'
  }
}

export default suppliersStyle
