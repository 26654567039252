import React, { useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'
import SearchConnectedSuppliers from '../SearchConnectedSuppliers/SearchConnectedSuppliers'
import Suppliers from '../Suppliers/Suppliers'
import SuppliersCriteria from '../SuppliersCriteria/SuppliersCriteria'
import { primaryColor } from '../../assets/jss/Colors'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}))

export default function LabTabs (props) {
  const [value, setValue] = React.useState(props.value)
  const isMounted = useRef(false)
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
    } else {
      if (value !== props.value) {
        setValue(props.value)
      }
    }
    // this function will be ran when the component is re-renderd or unmounted
    return () => {}
    //you need to add in this array the properties that need to be checked for changes and the ones you will be using in this function
    //, if one property changes, the useEffect function will be ran again (once per DOM change)
    // you can send all the props and if something updated this function will be ran
  }, [setValue, props, value])
  const classes = useStyles()

  const [suppliersCriteriaData, setSuppliersCriteriaData] = React.useState([])
  const [
    suppliersCriteriaSearchInfo,
    setSuppliersCriteriaSearchInfo
  ] = React.useState()

  const [suppliersContext, setSuppliersContext] = React.useState()

  const [suppliersBvdIdField, setSuppliersBvdIdField] = React.useState()

  const [suppliersBvd, setSuppliersBvd] = React.useState()

  const [
    searchConnectedSuppliersdata,
    setSearchConnectedSuppliersData
  ] = React.useState()
  const [
    searchConnectedSuppliersSearchInfo,
    setSearchConnectedSuppliersSearchInfo
  ] = React.useState()

  const [
    searchConnectedSuppliersRealm,
    setSearchConnectedSuppliersRealm
  ] = React.useState()

  const handleChange = (event, newValue) => {
    setValue(newValue)
    if (props.supplierPaths && props.supplierPaths[newValue] && props.history) {
      props.history.push(props.supplierPaths[newValue])
    }
  }

  return (
    <div className={classes.root}>
      <TabContext value={value}>
        <AppBar position="static">
          <TabList
            onChange={handleChange}
            aria-label="simple tabs example"
            style={{ backgroundColor: primaryColor }}
          >
            <Tab
              label="Search Connected Suppliers"
              value="1"
              style={{ textTransform: 'none' }}
            />
            <Tab
              label="Search Suppliers by BvD ID"
              value="2"
              style={{ textTransform: 'none' }}
            />
            <Tab
              label="Search Suppliers by Criteria"
              value="3"
              style={{ textTransform: 'none' }}
            />
          </TabList>
        </AppBar>

        <TabPanel value="1">
          <SearchConnectedSuppliers
            data={searchConnectedSuppliersdata}
            setData={setSearchConnectedSuppliersData}
            searchInfo={searchConnectedSuppliersSearchInfo}
            setSearchInfo={setSearchConnectedSuppliersSearchInfo}
            realm={searchConnectedSuppliersRealm}
            setRealm={setSearchConnectedSuppliersRealm}
            {...props}
          />
        </TabPanel>
        <TabPanel value="2">
          <Suppliers
            context={suppliersContext}
            bvdIdField={suppliersBvdIdField}
            bvdId={suppliersBvd}
            setContext={setSuppliersContext}
            setBvdIdField={setSuppliersBvdIdField}
            setBvdId={setSuppliersBvd}
            {...props}
          />
        </TabPanel>
        <TabPanel value="3">
          <SuppliersCriteria
            setSearchInfo={setSuppliersCriteriaSearchInfo}
            searchInfo={suppliersCriteriaSearchInfo}
            setPropData={setSuppliersCriteriaData}
            data={suppliersCriteriaData}
            {...props}
          />
        </TabPanel>
      </TabContext>
    </div>
  )
}
